export enum CheckType {
    VALID = 'valid',
    WARNING = 'warning',
    DANGER = 'danger',
    INIT = ''
}

export interface CheckResult {
    message: string;
    type: CheckType;
    payload?: any;
}

export interface Verifier<V> {
    check(value: V, message?: string): CheckResult;
}

