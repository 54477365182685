import React, { useEffect, useRef, useState } from "react";
import { PseTypes, documentTypeList } from "../types";
import { CheckResult, CheckType } from "../../../../../services/Verifiers/Verfier";
import nonSelectedVerifier from "../../../../../services/Verifiers/NonSelectedVerfier";
import transactionService from "../../../../../services/Services/TransactionService/TransactionService";
import Validator from "../../../../Validator/Validator";
import Button from "../../../../Buttons/Button/Button";
import Title from "../../../../Title/Title";
import "../Pse.scss";

interface PseInformationProps {
    backClick(): void;
    frontClick(): void;
    setDocumentType(value: string): void;
    setDocument(value: string): void;
    setBankName(value: string): void;
    setBank(value: string): void;
    user: any;
}

const PseInformation: React.FC<PseInformationProps> = (properties: PseInformationProps) => {
    const bankSelector = useRef<any>();
    const docuementTypeSelector = useRef<any>();

    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

    const [financialInstitutions, setFinancialInstitutions] = useState<any>([]);

    const [documentTypeAlert, setDocumentTypeAlert] = useState<CheckResult>({ type: CheckType.INIT, message: "" });
    const [bankAlert, setBankAlert] = useState<CheckResult>({ type: CheckType.INIT, message: "" });

    const [selectedDocument, setSelectedDocument] = useState<any>({});

    useEffect(() => {
        getInstitutions();
    }, []);

    useEffect(() => {
        if (documentTypeAlert.type === "valid" && selectedDocument.number && bankAlert.type === "valid")
            setButtonDisabled(false);
        else setButtonDisabled(true);
    }, [documentTypeAlert, bankAlert, selectedDocument]);

    const changeDocumentType = () => {
        const value = docuementTypeSelector.current.value;
        properties.setDocumentType(value);
        setDocumentTypeAlert(nonSelectedVerifier.check(value, "el tipo de documento"));
        handleSetDocument(value);
    };

    const handleSetDocument = (value: string) => {
        const userDocumentType = properties.user.documents?.find((document: any) => document.type === value);
        setSelectedDocument({ ...userDocumentType } || {});
        properties.setDocument(userDocumentType?.number);
    };

    const changeBank = () => {
        const selected = financialInstitutions.find((item: any) => item.code == bankSelector.current.value);
        properties.setBank(selected.code);
        properties.setBankName(selected.name);
        bankSelector.current.value === "0"
            ? setBankAlert(nonSelectedVerifier.check("null", "el banco"))
            : setBankAlert(nonSelectedVerifier.check(bankSelector.current.value, ""));
    };

    const getInstitutions = async () => {
        const institutionPaymentez = await transactionService.getPaymentezInstitutions();
        let banks = institutionPaymentez.data.banks;
        if (!banks) {
            setFinancialInstitutions([]);
            return;
        }
        banks[0] = { name: "Selecciona el banco", code: 0 };
        setFinancialInstitutions(institutionPaymentez.data.banks);
    };

    const showAlert = (type: string) => {
        switch (type) {
            case "documentType":
                return (
                    <Validator
                        showAlert={documentTypeAlert.type !== CheckType.VALID}
                        message={documentTypeAlert.message}
                        type={documentTypeAlert.type}
                    />
                );
            case "bank":
                return (
                    <Validator
                        showAlert={bankAlert.type !== CheckType.VALID}
                        message={bankAlert.message}
                        type={bankAlert.type}
                    />
                );
        }
    };

    return (
        <>
            <div className="swiper-slide__container">
                <Title
                    title={"PSE"}
                    arrows={[true, false]}
                    backClick={() => {
                        properties.backClick();
                    }}
                />
                <form>
                    <label>Tipo de documento</label>
                    <select
                        id="select-person"
                        className="bx--text-input"
                        name="select-person"
                        onChange={changeDocumentType}
                        ref={docuementTypeSelector}
                    >
                        {documentTypeList.map((documentType: PseTypes, i) => (
                            <option value={documentType.value} key={i}>
                                {documentType.name}
                            </option>
                        ))}
                    </select>
                    {showAlert("documentType")}
                    <label>No. de documento</label>
                    <input
                        value={selectedDocument?.number || ""}
                        disabled
                        type={"number"}
                        id="charge_id_number"
                        name="charge_id_number"
                        autoComplete="off"
                    />
                    {showAlert("document")}
                    <label>Banco</label>
                    <select
                        id="select-person"
                        className="bx--text-input"
                        name="select-person"
                        onChange={changeBank}
                        ref={bankSelector}
                    >
                        {financialInstitutions.map((institution: any, i: number) => (
                            <option value={institution.code} key={i}>
                                {institution.name}
                            </option>
                        ))}
                    </select>
                    {showAlert("bank")}
                </form>
            </div>
            <div className="charge__btn">
                <div className="info-text">
                    <Button
                        isIcon={false}
                        label={"Siguiente"}
                        disable={isButtonDisabled}
                        onClick={() => {
                            properties.frontClick();
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default PseInformation;
