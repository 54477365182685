export const LOGIN_SERVICE_BACKEND = process.env.REACT_APP_LOGIN_SERVICE!
export const EXCHANGE_RATE_SERVICE = process.env.REACT_APP_EXCHANGE_RATE_SERVICE!
export const SESSION_SERVICE_BACKEND = process.env.REACT_APP_SESSION_SERVICE!
export const TRANSACTION_SERVICE_BACKEND = process.env.REACT_APP_TRANSACTION_SERVICE!
export const KYC_SERVICE_BACKEND = process.env.REACT_APP_KYC_SERVICE!
export const USER_MANAGER_SERVICE_BACKEND = process.env.REACT_APP_USER_MANAGER_SERVICE!
export const ACCOUNTING_SERVICE_BACKEND = process.env.REACT_APP_ACCOUNTING_SERVICE!
export const COUNTRIES_SERVICE_BACKEND = 'https://countriesnow.space/api/v0.1'

export const CLIENT_ID = process.env.REACT_APP_METAMAP_CLIENT_ID!
export const FLOW_ID = process.env.REACT_APP_METAMAP_FLOW_ID!
export const FLOW_ID_TEST = process.env.REACT_APP_METAMAP_FLOW_ID_TEST!

export const INTERCOM_FLOW_ID = process.env.REACT_APP_INTERCOM_FLOW_ID!

export const TESTING = process.env.REACT_APP_TEST === 'true'
