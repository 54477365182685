import React, { useEffect, useState } from "react";
import { ImodalInformation } from "../../../Charge/Charge";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../../store/modalReducer";
import { useHistory } from "react-router";
import { ITotal } from "../../PaymentMethodsModels";
import transactionService from "../../../../../services/Services/TransactionService/TransactionService";
import IconProcess from "../../../../../assets/images/PROCESS.svg";
import Result from "../../../Result/Result";
import Title from "../../../../Title/Title";
import "../Pse.scss";

const TIMER_LENGTH_SECONDS = 5;

interface PseTransactionProps {
    stepIndex: number;
    total: ITotal;
    documentType: string;
    document: number;
    bankName: string;
    bank: string;
    backClick(): void;
    modalInformation: ImodalInformation;
}

const PseTransaction: React.FC<PseTransactionProps> = (properties: PseTransactionProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    let countdownInterval: any;

    const [timer, setTimer] = useState<number>(-2);
    const [isButtonEnabled, setButtonEnabled] = useState<boolean>(true);

    useEffect(() => {
        if (properties.stepIndex === 3) {
            setTimer(TIMER_LENGTH_SECONDS);
            return;
        }
    }, [properties.stepIndex]);

    useEffect(() => {
        countdownInterval = setInterval(() => {
            if (timer === 0) {
                clearInterval(countdownInterval);
                setButtonEnabled(false);
                createPSETransaction();
                return;
            }
            if (timer === -1) {
                clearInterval(countdownInterval);
                history.push("/dashboard/viio");
                return;
            }
            if (timer > 0) {
                setTimer((prevTimer) => prevTimer - 1);
            }
            if (timer < -1) clearInterval(countdownInterval);
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [timer]);

    const createPSETransaction = async () => {
        let result = await transactionService.createPaymentezPSETransaction({
            documentType: properties.documentType,
            document: properties.document,
            bank: properties.bank,
            bankName: properties.bankName,
            total: properties.total.quotePrice,
            rateId: properties.total.rateId,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        if (!result.success) {
            properties.modalInformation("Ha ocurrido un error, por favor intenta de nuevo", false);
            return;
        }
        dispatch(closeModal());
        window.location.replace(result.data);
    };

    const cancelTransaction = () => {
        setTimer(-1);
    };

    return (
        <>
            <Title title={"PSE"} arrows={[false, false]} backClick={properties.backClick} />
            <Result
                key={"awaiting_pse"}
                image={IconProcess}
                isIcon={false}
                icon="&#x63;"
                subtitle={"Estamos creando tu transacción"}
                warn={true}
                callbackOnClick={cancelTransaction}
                showButton={isButtonEnabled}
                buttonText={"Cancelar"}
                text={"Serás redirigido a la pasarela de pago de PSE"}
                subText={"Por favor espera un momento"}
                timer={timer}
            />
        </>
    );
};

export default PseTransaction;
