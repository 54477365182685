import AUD from "../assets/icon/countries/AUD.svg";
import CAD from "../assets/icon/countries/CAD.svg";
import COP from "../assets/icon/countries/COP.svg";
import ETH from "../assets/icon/countries/ETH.svg";
import EUR from "../assets/icon/countries/EUR.svg";
import GBP from "../assets/icon/countries/GBP.svg";
import MXN from "../assets/icon/countries/MXN.svg";
import USD from "../assets/icon/countries/USD.svg";
import USDC from "../assets/icon/countries/USDC.svg";
import USDT from "../assets/icon/countries/USDT.svg";
import VIIO from "../assets/icon/countries/VIIO.svg";

export { AUD, CAD, USDC, COP, ETH, EUR, GBP, MXN, USD, USDT, VIIO }

export let countryIcons: any = {
    AUD,
    CAD,
    COP,
    ETH,
    EUR,
    GBP,
    MXN,
    USD,
    USDC,
    USDT,
    VIIO
}
