import  { FC, useState, useEffect } from "react";
import { CheckType } from "../../../../services/Verifiers/Verfier";
import Validator from "../../../Validator/Validator";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import ibanVerifier from "../../../../services/Verifiers/IbanVerifier";


interface EURProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const EUR: FC<EURProps> = ({ handleGetData, defaultValues }) => {
  const [iban, setIban] = useState<any>({ type: CheckType.INIT, message: ""});
  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit])

  const handleIban = (target: any) => {
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el IBAN");
    else checker = ibanVerifier.check(target.value);
    setIban(checker);
    saveSubmit(target.value, target.name)
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value.toUpperCase();
    setSubmit({...submit})
  }

  const checkData = () => {
    const options = [iban];
    const isValid = options.every((option: any) => option.type === CheckType.VALID);
    handleGetData({
      isValid: isValid,
      data: { ...submit, accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}` }
    })
  }

  return (
    <form>
       <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*IBAN`}</label>
        <input
          value={submit.iban}
          name={"iban"}
          onChange={(e) => handleIban(e.target)}
        />
        <Validator
          showAlert={iban.type !== CheckType.VALID}
          message={iban.message}
          type={iban.type}
        />
      </div>
    </form>
  );
};

export default EUR;
