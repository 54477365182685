import React, { useEffect, useState } from "react";
import { buildTransactionDictionary, isOTCTransaction } from "./ActivityDefinitions";
import { TransactionType } from "../../../types/transactionTypes";
import { IonModal } from "@ionic/react";
import { Pair } from "../../../types/types";
import exchangeRateService from "../../../services/Services/ExchangeRateService/ExchangeRateService";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import userService from "../../../services/Services/UserService/UserService";
import icon from "../../../assets/images/pup_land/Frame_Original.svg";
import "./ActivityModal.scss";

interface ModalProps {
    open?: boolean;
    transaction?: any;
    onClose?: any;
}

const ActivityModal: React.FC<ModalProps> = ({ open, transaction, onClose }) => {
    const [isLoadingReceitp, setIsLoadingReceitp] = useState<boolean>(false);
    const [dictionary, setDictionary] = useState<any>([]);
    const [hasReceipt, setHasReceipt] = useState<boolean>(false);
    const [receitp, setReceipt] = useState<string>("");
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        fillDictionary(transaction);
    }, [transaction]);

    useEffect(() => {
        getTransactionReceipt();
        fillTransferPaymentDetails();
        fillDictionaryWithDetails();
    }, [reload, transaction]);

    const getTransactionReceipt = async () => {
        setReceipt("");
        setHasReceipt(false);
        let type = transaction?.TransactionTypes?.type;
        if (type !== TransactionType.OTC_DEPOSIT && type !== TransactionType.DEPOSIT) return;
        setHasReceipt(true);
        setIsLoadingReceitp(true);
        const { data } = await transactionService.downloadReceipt(transaction.reference);
        if (!data) {
            setHasReceipt(false);
            return;
        }
        setReceipt(data?.download);
        setIsLoadingReceitp(false);
    };

    const fillDictionary = (transaction: any) => {
        setDictionary(buildTransactionDictionary(transaction));
    };

    const fillTransferPaymentDetails = async () => {
        if (transaction?.TransactionTypes?.type === TransactionType.TRANSFER) {
            let response = await userService.getTransferLinkedDetails(transaction?.transactionId);
            if (response?.succesful) {
                let newDictionary = dictionary.length > 0 ? dictionary : buildTransactionDictionary(transaction);
                newDictionary.payment[0].value = response?.result?.email;
                newDictionary.payment[1].value = response?.result?.name;
                setDictionary(newDictionary);
            }
        }
    };

    const fillDictionaryWithDetails = async () => {
        const pairs: Pair[] = await exchangeRateService.getPairs();
        if (pairs.length > 0) {
            let pair = pairs.find((p) => p.name === `${fixName(transaction.fromDetails?.ticker)}-${fixName(transaction.toDetails?.ticker)}`);
            if (pair === undefined) pair = pairs.find((p) => p.name === `${fixName(transaction.toDetails?.ticker)}-${fixName(transaction.fromDetails?.ticker)}`);
            if (pair) {
                let newDictionary = dictionary.length > 0 ? dictionary : buildTransactionDictionary(transaction);
                newDictionary.details[3].value = newDictionary.details[3].value + " " + pair?.name.replace("-", "/") ?? "";
                newDictionary.details[4].value = `${pair?.commission ?? 0}`;
                setDictionary(newDictionary);
            }
        }
    };

    const returnTransactionLink = (hash: string): string => {
        return isOTCTransaction(transaction) ? `https://etherscan.io/tx/${hash}` : `https://polygonscan.com/tx/${hash}`;
    };

    const fixName = (name: string) => {
        return name?.includes("USDC") ? "USDC" : name;
    }

    return (
        <IonModal isOpen={open} className="activity__modal-main" onWillDismiss={() => onClose()}>
            <div className="activity__modal-container hide-scrollbar">
                <div>
                    <div className="activity__img-container">
                        <img src={icon} alt={"Detalle de transacción"} width="90" />
                    </div>
                    <p className="activity__section-title">Detalles de transaccíon</p>
                    <div className="activity__content">
                        {dictionary?.details?.map((item: any, i: number) => (
                            <div key={i}>
                                {item?.value && item.value != "undefined" && (
                                    <div className="activity__item">
                                        <span>{item?.title}</span>
                                        <span>{item.value}</span>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="activity__item">
                            <span>Referencia</span>
                            <div
                                onClick={() => {
                                    navigator.clipboard.writeText(`${transaction?.reference}`);
                                }}
                                className="receipt-button colored"
                            >
                                Copiar
                            </div>
                        </div>
                    </div>
                    {dictionary?.payment?.length > 0 && (
                        <>
                            <p className="activity__section-title">Información de pago</p>
                            <div className="activity__content">
                                {dictionary?.payment?.map((item: any, i: number) => (
                                    <div key={i}>
                                        {item?.value &&
                                            item.value != "undefined" &&
                                            item?.title != "Hash de la transacción" && (
                                                <div className="activity__item">
                                                    <span>{item?.title}</span>
                                                    <span>{item.value}</span>
                                                </div>
                                            )}
                                        {item?.value &&
                                            item.value != "undefined" &&
                                            item?.title === "Hash de la transacción" && (
                                                <div className="activity__item">
                                                    <span>{item?.title}</span>
                                                    <span>
                                                        <a href={returnTransactionLink(item.value)} target="_blank">
                                                            {item.value}
                                                        </a>
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                ))}
                                {hasReceipt && (
                                    <div className="activity__item">
                                        <span>Comprobante</span>
                                        {!receitp ? (
                                            <>
                                                {isLoadingReceitp ? (
                                                    <span className="receipt-button disabled">Cargando </span>
                                                ) : (
                                                    <span className="receipt-button" onClick={() => setReload(!reload)}>
                                                        Reintentar
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <a href={receitp} target="_blank" className="receipt-button colored">
                                                Descargar
                                            </a>
                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="custom-button" onClick={onClose}>
                    <span>Entendido</span>
                </div>
            </div>
        </IonModal>
    );
};

export default ActivityModal;
