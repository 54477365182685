import React, { useEffect, useState } from "react";
import GoogleAuth from "./AuthMethods/GoogleAuth";
import Title from "../../../Title/Title";
import Pin from "./AuthMethods/Pin";

interface AuthMethodsProps {
    backClick: any;
}

export enum Methods {
    PIN = "PIN",
    OTP = "OTP",
}

const AuthMethods: React.FC<AuthMethodsProps> = ({ backClick }) => {
    const [selectedMethod, setSelectedMethod] = useState<Methods>();

    const selectPinMethod = () => {
        if (selectedMethod == Methods.PIN) setSelectedMethod(undefined);
        else setSelectedMethod(Methods.PIN);
    };

    const selectGoogleAuthMethod = () => {
        if (selectedMethod == Methods.OTP) setSelectedMethod(undefined);
        else setSelectedMethod(Methods.OTP);
    };

    return (
        <>
            <div className="swiper-slide__container">
                <Title
                    title={"Métodos de autenticación"}
                    arrows={[true, false]}
                    backClick={backClick}
                />
                <div className="cards_container">
                    <div className="card" onClick={selectPinMethod}>
                        <Pin selectedMethod={selectedMethod} />
                    </div>
                    {/*<div className="card" onClick={() => selectGoogleAuthMethod()}>
                        <GoogleAuth selectedMethod={selectedMethod} />
                    </div>*/}
                </div>
            </div>
        </>
    );
};

export default AuthMethods;
