import { FC, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { IonContent } from "@ionic/react";
import "./Tutorial.scss";

import TutorialOne from "../../assets/images/tutorial/01.svg";
import TutorialTwo from "../../assets/images/tutorial/02.svg";
import TutorialThree from "../../assets/images/tutorial/03.svg";
import TutorialFour from "../../assets/images/tutorial/04.svg";

import Title from "../Title/Title";
import Button from "../Buttons/Button/Button";

import { useHistory } from "react-router";

import userService from "../../services/Services/UserService/UserService";

interface Props {}

const Tutorial: FC<Props> = () => {
  const history = useHistory();

  const swiperNavPrevRef = useRef<any>();
  const swiperNavNextRef = useRef<any>();
  const [swaperInstance, setSwiperInstance] = useState<any>();

  const changeSlide = (swaperInstance: any, event: string) => {
    if (event === "prev") {
      swaperInstance.slidePrev(500);
      return;
    }
    swaperInstance.slideNext(500);
  };

  const handleRedirect = async () => {
    await userService.changeTutorialStatus({ status: true });
    history.push("dashboard/viio", { replace: true });
  };

  return (
    <IonContent>
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl: swiperNavPrevRef.current,
          nextEl: swiperNavNextRef.current,
        }}
        slidesPerView={1}
        spaceBetween={400}
        allowTouchMove={false}
        onInit={(swiper: any) => {
          swiper.params.navigation.prevEl = swiperNavPrevRef.current;
          swiper.params.navigation.nextEl = swiperNavNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
      >
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Ánclate al dólar"}
              arrows={[false, true]}
              nextClick={() => changeSlide(swaperInstance, "next")}
            />
            <div className="tutorial-content">
              <div className="tutorial-items">
                <img src={TutorialOne} className="tutorial-img" />
                <p>Recarga, retira, guarda y envía dólares USDC.</p>
                <p>Con VIIO no pierdes valor si tu moneda local se devalúa.</p>
              </div>
              <Button
                isIcon={false}
                label={"Continuar"}
                onClick={() => changeSlide(swaperInstance, "next")}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Transfiere internacionalmente"}
              arrows={[true, true]}
              nextClick={() => changeSlide(swaperInstance, "next")}
              backClick={() => changeSlide(swaperInstance, "prev")}
            />
            <div className="tutorial-content">
              <div className="tutorial-items">
                <img src={TutorialTwo} className="tutorial-img" />
                <p>
                  Envía dólares USDC hacia cualquier lugar del mundo sin costo.
                </p>
                <p>
                  Transfiere a tus amigos VIIO o, a tus cuentas bancarias
                  personales en el exterior sin limitaciones.
                </p>
              </div>
              <Button
                isIcon={false}
                label={"Continuar"}
                onClick={() => changeSlide(swaperInstance, "next")}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Puedes estar tranquilo"}
              arrows={[true, true]}
              nextClick={() => changeSlide(swaperInstance, "next")}
              backClick={() => changeSlide(swaperInstance, "prev")}
            />
            <div className="tutorial-content">
              <div className="tutorial-items">
                <img src={TutorialThree} className="tutorial-img" />
                <p>VIIO es la cuenta global más segura del mercado.</p>
                <p>
                  Contamos con un esquema de seguridad imbatible gracias a la
                  tecnología y respaldo de nuestros aliados.
                </p>
              </div>
              <Button
                isIcon={false}
                label={"Continuar"}
                onClick={() => changeSlide(swaperInstance, "next")}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Toma el control"}
              arrows={[true, false]}
              backClick={() => changeSlide(swaperInstance, "prev")}
            />
            <div className="tutorial-content">
              <div className="tutorial-items">
                <img src={TutorialFour} className="tutorial-img" />
                <p>
                  Con VIIO tienes el control de tu vida financiera. Tú decides
                  de qué manera gestionar tus activos digitales.
                </p>
                <p>
                  Tus dólares USDC están siempre disponibles en tu cuenta
                  global.
                </p>
              </div>
              <Button
                isIcon={false}
                label={"Continuar"}
                onClick={() => handleRedirect()}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </IonContent>
  );
};

export default Tutorial;
