import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const splitDomain = event.url
        const domain = ''+ (process.env.REACT_APP_DEEPLINK_DOMAIN || 'wallet.viio.me');
        const slug = splitDomain.split(domain).pop();
        //const domain1 = ''+ ('webclient.prod.viio.dev')
        //const slug1 = event.url.split(domain1).pop();
        if (slug) {
          history.push(''+slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;