import { useState } from "react";
import { IonPage, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useHistory } from "react-router";
import Header from "../../components/Header/Header";
import Register from "../../components/Session/Register/Register";
import "./RegisterPage.css";

const RegisterPage: React.FC = () => {
  const [render, setRender] = useState<boolean>(false);

  const history = useHistory();

  const goDashboard = () => {
    history.push("/login");
  };

  // ! SOLUCION TEMPORAL PARA RENDERIZAR DE NUEVO EL COMPONENTE
  useIonViewDidLeave(() => setRender(false));
  useIonViewDidEnter(() => setRender(true));

  return (
    <IonPage>
      <Header viio={false} />
      {render && <Register home={goDashboard} />}
    </IonPage>
  );
};

export default RegisterPage;
