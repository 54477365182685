import { useState, FC, useEffect } from "react";
import { IonContent } from "@ionic/react";
import { useHistory } from "react-router";
import showPasswordImg from "../../../assets/icon/password/show-password.svg";
import hidePasswordImg from "../../../assets/icon/password/hide-password.svg";
import loadingIcon from "../../../assets/icon/loading/loading.svg";
import userService from "../../../services/Services/UserService/UserService";
import Title from "../../Title/Title";
import "swiper/css";
import "./User.scss";

interface Props {}

const User: FC<Props> = () => {
    const history = useHistory();

    const [showAddress, setShowAddress] = useState<boolean>(false);
    const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

    const [credentials, setCredentials] = useState<any>({});
    const [address, setAddress] = useState<string>("");

    useEffect(() => {
        getCredentials();
    }, []);

    const goDashboard = () => {
        history.push("/dashboard/config");
    };

    const getPhone = (data: any) => {
        if (!data) return;
        if (data.number) return "+" + data.prefix + " " + data.number;
        return data;
    };

    const getCredentials = async () => {
        try {
            const response: any = await userService.getUserCredentials();
            setCredentials(response?.data);
        } catch (err) {}
    };

    const getAddress = async () => {
        const response: any = await userService.getUserAddress();
        if (response?.succesful) {
            setAddress(response?.data?.address as string);
            setShowAddress(!showAddress);
        }
    };

    const handleAddress = async () => {
        if (address) setShowAddress(!showAddress);
        else {
            setLoadingAddress(true);
            await getAddress();
            setLoadingAddress(false);
        }
    };

    const getUserAdress = () => {
        if (!credentials.address) return;
        let result: string[] = []
        if (credentials?.address?.country) result.push(credentials?.address?.country);
        if (credentials?.address?.city) result.push(" " + credentials?.address?.city);
        if (credentials?.address?.address) result.push(" " + credentials?.address?.address);
        return result.join();
    }

    return (
        <IonContent className="ion-content">
            <Title title="Mis datos" arrows={[true, false]} backClick={() => goDashboard()} />
            <div className="user__content">
                <div className="user__item">
                    <span>NOMBRE:</span>
                    <span>
                        {credentials?.firstName} {credentials?.lastName}
                    </span>
                </div>
                <div className="user__item">
                    <span>EMAIL:</span>
                    <span>{credentials?.email}</span>
                </div>
                <div className="user__item">
                    <span>TELÉFONO:</span>
                    <span>{getPhone(credentials?.phone)}</span>
                </div>
                {credentials?.address && (
                    <div className="user__item">
                        <span>DIRECCIÓN:</span>
                        <span>{getUserAdress()}</span>
                    </div>
                )}
                {/* <div className="user__item">
                    <span>WALLET:</span>
                    <span>
                        {loadingAddress ? (
                            <img className="animation" src={loadingIcon} alt="" />
                        ) : (
                            <>
                                {showAddress ? <>{address} </> : <b>· · · · · </b>}
                                <img
                                    src={showAddress ? showPasswordImg : hidePasswordImg}
                                    alt=""
                                    onClick={() => handleAddress()}
                                />
                            </>
                        )}
                    </span>
                </div> */}
            </div>
        </IonContent>
    );
};

export default User;
