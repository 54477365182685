export const documentTypes = [
  { id: 1, name: "CC: Cedula de Ciudadania" },
  { id: 2, name: "CE: Cedula de Extranjeria" },
  { id: 3, name: "NIP: Numero De Identificacion Personal" },
  { id: 4, name: "NIT: Numero De Identificacion Tributaria" },
  { id: 5, name: "PAP: Pasaporte" },
];

export const financialEntities = [
  {
    paymentezName: "ALIANZA FIDUCIARIA S A",
    bancolombiaName: "",
    bancolombiaCode: ""
  },
  {
    paymentezName: "BANCO CREDIFINANCIERA SA",
    bancolombiaName: "BANCO CREDIFINANCIERA SA",
    bancolombiaCode: "1558"
  },
  {
    paymentezName: "BANCAMIA",
    bancolombiaName: "BANCAMIA",
    bancolombiaCode: "1059",
  },
  {
    paymentezName: "BANCO AGRARIO",
    bancolombiaName: "BANCO AGRARIO",
    bancolombiaCode: "1040",
  },
  {
    paymentezName: "BANCO AGRARIO DESARROLLO",
    bancolombiaName: "",
    bancolombiaCode: "",
  },
  {
    name: "BANCO AGRARIO QA DEFECTOS",
    bancolombiaName: "",
    bancolombiaCode: "",
  },
  {
    paymentezName: "BANCO CAJA SOCIAL DESARROLLO",
    bancolombiaName: "Banco BCSC",
    bancolombiaCode: "1032",
  },
  {
    paymentezName: "BANCO COMERCIAL AVVILLAS S.A.",
    bancolombiaName: "BANCO AV VILLAS",
    bancolombiaCode: "1052",
  },
  {
    paymentezName: "BANCO COOMEVA S.A. - BANCOOMEVA",
    bancolombiaName: "BANCOOMEVA",
    bancolombiaCode: "1061",
  },
  {
    paymentezName: "BANCO COOPERATIVO COOPCENTRAL",
    bancolombiaName: "BANCO COOPERATIVO COOPCENTRAL",
    bancolombiaCode: "1066",
  },
  {
    paymentezName: "BANCO DAVIVIENDA",
    bancolombiaName: "BANCO DAVIVIENDA SA",
    bancolombiaCode: "1051",
  },
  {
    paymentezName: "BANCO DE BOGOTA DESARROLLO 2013",
    bancolombiaName: "BANCO DE BOGOTÁ",
    bancolombiaCode: "1001",
  },
  {
    paymentezName: "BANCO DE OCCIDENTE",
    bancolombiaName: "BANCO DE OCCIDENTE",
    bancolombiaCode: "1023",
  },
  {
    paymentezName: "BANCO FALABELLA",
    bancolombiaName: "BANCO FALABELLA S.A.",
    bancolombiaCode: "1062",
  },
  {
    paymentezName: "BANCO FINANDINA S.A BIC",
    bancolombiaName: "BANCO FINANDINA S.A.",
    bancolombiaCode: "1063",
  },
  {
    paymentezName: "BANCO GNB SUDAMERIS",
    bancolombiaName: "BANCO GNB SUDAMERIS",
    bancolombiaCode: "1012",
  },
  {
    paymentezName: "BANCO MUNDO MUJER S.A.",
    bancolombiaName: "BANCO NUMDO MUJER",
    bancolombiaCode: "1047",
  },
  {
    paymentezName: "BANCO PICHINCHA S.A.",
    bancolombiaName: "BANCO PICHINCHA",
    bancolombiaCode: "1060",
  },
  {
    paymentezName: "BANCO POPULAR",
    bancolombiaName: "BANCO POPULAR",
    bancolombiaCode: "1002",
  },
  {
    paymentezName: "BANCO SANTANDER COLOMBIA",
    bancolombiaName: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A",
    bancolombiaCode: "1065",
  },
  {
    paymentezName: "BANCO SERFINANZA",
    bancolombiaName: "BANCO SERFINANZA S.A",
    bancolombiaCode: "1069",
  },
  {
    paymentezName: "BANCOLOMBIA QA",
    bancolombiaName: "BANCOLOMBIA",
    bancolombiaCode: "1007",
  },
  {
    paymentezName: "BBVA COLOMBIA S.A.",
    bancolombiaName: "BBVA COLOMBIA",
    bancolombiaCode: "1013",
  },
  {
    paymentezName: "CITIBANK COLOMBIA S.A.",
    bancolombiaName: "CITIBANK",
    bancolombiaCode: "1009",
  },
  {
    paymentezName: "IRIS",
    bancolombiaName: "IRIS",
    bancolombiaCode: "1637",
  },
  {
    paymentezName: "ITAU",
    bancolombiaName: "ITAU antes Corpibanca",
    bancolombiaCode: "1014",
  },
  {
    paymentezName: "LULO BANK",
    bancolombiaName: "LULO BANK S.A.",
    bancolombiaCode: "1070",
  },
  {
    paymentezName: "MOVII S.A",
    bancolombiaName: "MOVII",
    bancolombiaCode: "1801",
  },
  {
    paymentezName: "RAPPIPAY",
    bancolombiaName: "RAPPIPAY",
    bancolombiaCode: "1811",
  },
  {
    paymentezName: "SCOTIABANK COLPATRIA DESARROLLO",
    bancolombiaName: "SCOTIABANK COLPATRIA S.A",
    bancolombiaCode: "1019",
  },
  {
    paymentezName: "COOPERATIVA FINANCIERA DE ANTIOQUIA",
    bancolombiaName: "COOPERATIVA FINANCIERA DE ANTIOQUIA",
    bancolombiaCode: "1283",
  },
  {
    paymentezName: "COOPERATIVA FINANCIERA COTRAFA",
    bancolombiaName: "COOTRAFA COOPERATIVA FINANCIERA",
    bancolombiaCode: "1289",
  },
  {
    paymentezName: "DAVIPLATA",
    bancolombiaName: "DAVIPLATA",
    bancolombiaCode: "1151",
  },
  {
    paymentezName: "COLTEFINANCIERA",
    bancolombiaName: "COLTEFINANCIERA S.A",
    bancolombiaCode: "1370",
  },
  {
    paymentezName: "CONFIAR COOPERATIVA FINANCIERA",
    bancolombiaName: "CONFIAR COOPERATIVA FINANCIERA",
    bancolombiaCode: "1292",
  },
  {
    paymentezName: "COOFINEP COOPERATIVA FINANCIERA",
    bancolombiaName: "COOFINEP COOPERATIVA FINANCIERA",
    bancolombiaCode: "1291",
  },
  {
    paymentezName: "BANCO UNION",
    bancolombiaName: "BANCO UNION S.A",
    bancolombiaCode: "1303",
  },
  {
    paymentezName: "BANCO J.P. MORGAN COLOMBIA S.A.",
    bancolombiaName: "BANCO J.P. MORGAN COLOMBIA S.A",
    bancolombiaCode: "1071",
  },
  {
    paymentezName: "NEQUI",
    bancolombiaName: "NEQUI",
    bancolombiaCode: "1507",
  },
  {
    paymentezName: "DALE",
    bancolombiaName: "",
    bancolombiaCode: "",
  },
  {
    paymentezName: "BANKA",
    bancolombiaName: "",
    bancolombiaCode: "",
  },
  {
    paymentezName: "",
    bancolombiaName: "JFK COOPERATIVA FINANCIERA",
    bancolombiaCode: "1286",
  },
  {
    paymentezName: "",
    bancolombiaName: "PIBANK",
    bancolombiaCode: "1560",
  },
  {
    paymentezName: "",
    bancolombiaName: "DING TECNIPAGOS SA",
    bancolombiaCode: "1802",
  },
  {
    paymentezName: "",
    bancolombiaName: "UALA",
    bancolombiaCode: "1804",
  },
  {
    paymentezName: "",
    bancolombiaName: "BANCO BTG PACTUAL",
    bancolombiaCode: "1805",
  },
  {
    paymentezName: "",
    bancolombiaName: "POWWI",
    bancolombiaCode: "1803",
  },
  {
    paymentezName: "",
    bancolombiaName: "BANCOLDEX S.A",
    bancolombiaCode: "1031",
  },
  {
    paymentezName: "",
    bancolombiaName: "MIBANCO S.A",
    bancolombiaCode: "1067",
  },
  {
    paymentezName: "",
    bancolombiaName: "ASOPAGOS S.A.S",
    bancolombiaCode: "1086",
  },
  {
    paymentezName: "",
    bancolombiaName: "FINANCIERA JURISCOOP S.A. COMPAÑIA DE FINANCIAMIENTO",
    bancolombiaCode: "1121",
  },
];