import { FC, useEffect, useState } from "react";
import { financialEntities } from "./data";
import { documentTypeList } from "../../PaymentMethods/PSE/types";
import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonSelectedVerifier from "../../../../services/Verifiers/NonSelectedVerfier";
import documentVerifier from "../../../../services/Verifiers/DocumentVerifier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import accountVerifier from "../../../../services/Verifiers/AccountVerifier";
import Validator from "../../../Validator/Validator";

interface COPProps {
    handleGetData: (data: any) => any;
    defaultValues?: any;
}

const COP: FC<COPProps> = ({ handleGetData, defaultValues }) => {
    const [accountNumber, setAccountNumber] = useState<any>({ type: CheckType.INIT, message: "" });
    const [documentType, setDocumentType] = useState<any>({ type: CheckType.INIT, message: "" });
    const [accountType, setAccountType] = useState<any>({ type: CheckType.INIT, message: "" });
    const [bankId, setBankId] = useState<any>({ type: CheckType.INIT, message: "" });
    const [banks, setBanks] = useState<any>([]);

    const [submit, setSubmit] = useState<any>({});

    const [selectedDocument, setSelectedDocument] = useState<any>({});

    useEffect(() => {
        let entities = financialEntities.filter((item: any) => item.bancolombiaName && item.bancolombiaCode);
        setBanks(entities);
        checkData();
    }, [submit]);

    // const handleDocumentNumber = (target: any) => {
    //   let checker: any = {};
    //   if (target.value === "")
    //     checker = nonEmptyVerifier.check(target.value, "el documento");
    //   else checker = documentVerifier.check(target.value);
    //   setDocumentNumber(checker)
    //   saveSubmit(target.value, target.name)
    // };

    const handleAccountNumber = (target: any) => {
        let checker: any = {};
        if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el numero de cuenta");
        else checker = accountVerifier.check(target.value);
        setAccountNumber(checker);
        saveSubmit(target.value, target.name);
    };

    const handleAccountType = (target: any) => {
        let checker = nonSelectedVerifier.check(target.value, "el tipo de cuenta");
        setAccountType(checker);
        saveSubmit(target.value, target.name);
    };

    const handleDocumentType = (target: any) => {
        let checker = nonSelectedVerifier.check(target.value, "el tipo de documento");
        setDocumentType(checker);
        saveSubmit(target.value, target.name);
        handleSetDocument(target.value);
    };

    const handleSetDocument = (value: string) => {
        const userDocumentType = defaultValues.documents?.find((document: any) => document?.type === value);
        setSelectedDocument({ ...userDocumentType } || {});
        saveSubmit(userDocumentType?.number, "documentNumber");
    };

    const handleBankId = (id: any) => {
        const bank = banks.find((item: any) => item.bancolombiaCode == id);
        let checker = nonSelectedVerifier.check(id, "el banco");
        setBankId(checker);
        saveSubmit(bank?.bancolombiaCode, "bankId");
        saveSubmit(bank?.bancolombiaName, "bankName");
    };

    const saveSubmit = (value: any, key: string) => {
        const copy = submit;
        copy[key] = value;
        setSubmit({ ...submit });
    };

    const checkData = () => {
        const options = [accountNumber, accountType, documentType, bankId];
        const isValid = options.every((option: any) => option.type === CheckType.VALID) && selectedDocument.number;
        handleGetData({
            isValid: isValid,
            data: {
                ...submit,
                accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
                name: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
            },
        });
    };

    return (
        <form>
            <div>
                <label>{`Nombre del titular`}</label>
                <input disabled type="text" value={`${defaultValues?.firstName} ${defaultValues?.lastName}`} />
                <Validator showAlert={false} message={""} type={""} />
            </div>
            <div>
                <label>{`*Tipo de documento`}</label>
                <select required name={"documentType"} onChange={(e) => handleDocumentType(e.target)}>
                    {documentTypeList.map((item: any) => (
                        <option value={item.value}>{item.name}</option>
                    ))}
                </select>
                <Validator
                    showAlert={documentType.type !== CheckType.VALID}
                    message={documentType.message}
                    type={documentType.type}
                />
            </div>
            <div>
                <label>{`*Número de documento`}</label>
                <input
                    defaultValue={selectedDocument?.number}
                    disabled
                    required
                    name={"documentNumber"}
                />
                <Validator showAlert={false} message={""} type={""} />
            </div>
            <div>
                <label>{`*Banco`}</label>
                <select onChange={(e) => handleBankId(e.target.value)}>
                    <option value={""}>Seleccione el banco</option>
                    {banks.map((item: any) => (
                        <option value={item.bancolombiaCode}>{item.bancolombiaName}</option>
                    ))}
                </select>
                <Validator showAlert={bankId.type !== CheckType.VALID} message={bankId.message} type={bankId.type} />
            </div>
            <div>
                <label>{`*Tipo de cuenta`}</label>
                <select required name={"accountType"} onChange={(e) => handleAccountType(e.target)}>
                    <option value={""}>{"Seleccione el tipo de cuenta"}</option>
                    <option value={"1"}>Cuenta De Ahorros</option>
                    <option value={"2"}>Cuenta Corriente</option>
                </select>
                <Validator
                    showAlert={accountType.type !== CheckType.VALID}
                    message={accountType.message}
                    type={accountType.type}
                />
            </div>
            <div>
                <label>{`*Número de cuenta`}</label>
                <input required type="number" name="accountNumber" onChange={(e) => handleAccountNumber(e.target)} />
                <Validator
                    showAlert={accountNumber.type !== CheckType.VALID}
                    message={accountNumber.message}
                    type={accountNumber.type}
                />
            </div>
        </form>
    );
};

export default COP;
