import React, { useEffect, useState } from "react";
import { countryIcons } from "../../../types/export";
import { IonContent, useIonViewDidEnter } from "@ionic/react";
import exchangeRateService from "../../../services/Services/ExchangeRateService/ExchangeRateService";
import ButtonLink from "../../Buttons/ButtonLink/ButtonLink";
import "./ExchangeRate.scss";

import transactionService from "../../../services/Services/TransactionService/TransactionService";

interface ExchangeRateProps {}

const ExchangeRate: React.FC<ExchangeRateProps> = () => {
    const [exchangeRate, setExchangeRate] = useState<any[]>([]);

    useIonViewDidEnter(() => {
        transactionService.updateExternalDeposit();
    })

    useEffect(() => {
        getRates();
    }, []);

    const getRates = async () => {
        let rates: any = await exchangeRateService.getRates();
        rates = filterRates(rates);
        setExchangeRate(rates || []);
    };

    const filterRates = (rates: any) => {
        let filteredRates: any[] = [];
        rates.forEach((rate: any) => {
            if (rate.name !== "USDC-USDC" &&  rate.name !== "USDC-ETH" && rate.name !== "USDC-USDT") filteredRates.push(rate);
        });
        return filteredRates;
    };

    const fixName = (name: string) => {
        name = name ? name.replace("-", "/") : name;
        return name;
    };

    const getCountryIcon = (name: string) => {
        if (name) {
            let countries = name.split("-");
            return countryIcons[`${countries[1]}`];
        }
    };

    const showExchangeRates = () => {
        return (
            <>
                {exchangeRate?.map((exRate: any, i: number) => (
                    <ButtonLink
                        key={i}
                        mode={"info"}
                        label={fixName(exRate.name)}
                        subLabel={`${exRate.description?.es}`}
                        src={getCountryIcon(exRate.name)}
                        outline="none"
                        info={`${exRate.name === 'USDC-COP'? exRate.buyingPrice: exRate.sellingPrice}`}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            <div className="padd" />
            <IonContent>
                <div className="box-shadow">{showExchangeRates()}</div>
            </IonContent>
        </>
    );
};

export default ExchangeRate;
