import { useHistory, useLocation } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import sessionService from "../../services/Services/SessionService/SessionService";
import { useDispatch } from "react-redux";

interface Props {
    children: any;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch()

    const [isAuthed, setIsAuthed] = useState<boolean>();

    useEffect(() => {
        isUserAuthed();
    }, []);

    useEffect(() => {
         if (isAuthed === false) {
             history.push("/");
         }
    }, [isAuthed]);

    const isUserAuthed = async () => {
        const authResult = await sessionService.verifyTokenValidity();
        setIsAuthed(authResult);
    };

    return children;
};

export default ProtectedRoute;
