import React, { useEffect, useState } from "react";
import "./VerificationCodeInput.scss";

interface Props {
  length: number;
  handleGetCode?: any;
  cleanSetCode?: any;
  cleanStatus?: any;
}

export const enum VerficationCodeStatuses {
  CLEAN = "CLEAN",
  READY = "READY"
}

const VerificationCodeInput: React.FC<Props> = ({
  length,
  handleGetCode = () => null,
  cleanSetCode = () => null,
  cleanStatus
}) => {
  const itemsRef: any = React.useRef([]);
  const [code, setCode] = React.useState<any>(new Array(length).fill(""));


  const [focusedIndex, setFocusedIndex] = useState<any>();

  const handleFocus = (target: any) => {
    const index = itemsRef.current.findIndex((item: any) => item == target);
    setFocusedIndex(index);
  };

  useEffect(()=>{
    const clearInput = () => {
      setCode(new Array(length).fill(""))
    };
    if(cleanStatus === VerficationCodeStatuses.CLEAN) {
      clearInput();
      cleanSetCode(VerficationCodeStatuses.READY)
    }
  }, [cleanStatus])

  const handleChange = (e: any) => {
    if (code[focusedIndex]) itemsRef.current[focusedIndex + 1]?.focus();
    if (e.target.value.length > 1 && !/^[0-9]$/.test(e.target.value)) return;
    itemsRef.current[focusedIndex]?.focus();
    const codeDigits = code;
    codeDigits[focusedIndex] = e.target.value;
    setCode([...codeDigits]);
    handleGetCode([...codeDigits].join(""));
    if (!e.target.value) return;
    itemsRef.current[focusedIndex + 1]?.focus();
  };

  const handleKeyPressed = (e: any) => {
    if (e.keyCode !== 8 || code[focusedIndex]) return;
    itemsRef.current[focusedIndex - 1]?.focus();
  };

  return (
    <div className="verification-code">
      {new Array(length).fill("").map(function (x, idx) {
        return (
          <input
            ref={(e) => (itemsRef.current[idx] = e)}
            className="cVerification__input"
            value={code[idx]}
            type="text"
            key={idx}
            onChange={(e) => handleChange(e)}
            onKeyDownCapture={(e) => handleKeyPressed(e)}
            onFocus={(e) => handleFocus(e.target)}
          />
        );
      })}
    </div>
  );
};

export default VerificationCodeInput;
