import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { LOGIN_SERVICE_BACKEND } from "../../BackendServicesExports";
// import { ILogin, ILoginResponse } from "./RegisterModels";
import sessionService from "../SessionService/SessionService";
import axios from "axios";

class RegisterService {

    private loginServiceUrl: string;

    constructor(loginServiceUrl: string) {
        this.loginServiceUrl = loginServiceUrl;
    }

    public async register(registerData: any): Promise<any> {
        try {
            const result = await axios.post(`${this.loginServiceUrl}/v1/register`, registerData, buildAuthorizationConfig());;
            return { succesful: true, data: result.data };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async put(registerData: any): Promise<any> {
        try {
            const result = await axios.put(`${this.loginServiceUrl}/v1/register`, registerData, buildAuthorizationConfig());
            sessionService.setTokenToStorage(result.data?.jwt);
            return { succesful: true };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }
}

const registerService = new RegisterService(LOGIN_SERVICE_BACKEND);
export default registerService;