import React, { useEffect, useState } from "react";
import transactionService from "../../../../services/Services/TransactionService/TransactionService";
import { ITotal } from "../PaymentMethodsModels";
import SubTitle from "../../../SubTitle/SubTitle";
import Button from "../../../Buttons/Button/Button";
import Compare from "../../../Compare/Compare";
import Select from "../../../DynamicForms/Select/Select";
import Title from "../../../Title/Title";
import VIIO from "../../../../assets/images/VIIO.svg";
import FILE from "../../../../assets/images/FILE.svg";
import "./BankTransfer.scss";
import { useHistory } from "react-router";
import IconProcess from "../../../../assets/images/PROCESS.svg";
import IconSuccess from "../../../../assets/images/pup_land/Frame.svg";
import { TESTING } from "../../../../services/BackendServicesExports";

interface BankTransferProps {
  bankAccounts?: any;
  onSelectAccount?: any;
  selectedAccount?: any;
  accountDetails?: any;
  total: ITotal;
  name: BankTransferStates;
  backClick(): void;
  frontClick(): void;
  modalInformation(
    message: string,
    disable: boolean,
    title?: string,
    icon?: any,
    page?: string,
    hideButtons?: boolean,
    secondaryContent?: string,
    label?: string
  ): void;
  user: any;
}

export enum BankTransferStates {
  SELECTION = "selection",
  UPLOAD = "upload",
}

const BankTransfer: React.FC<BankTransferProps> = ({
  total,
  name,
  modalInformation,
  backClick,
  frontClick,
  user,
  bankAccounts,
  onSelectAccount = () => null,
  selectedAccount,
  accountDetails,
}) => {

  const [fileToUpload, setFileToUpload] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const extractCurrencies = (exchangeRate: string) => {
    if (exchangeRate) {
      let currencies = exchangeRate.split("-");
      return {
        baseCurrencyTicker: currencies[0],
        quoteCurrencyTicker: currencies[1],
      };
    }
    return { baseCurrencyTicker: "", quoteCurrencyTicker: "" };
  };

  const createTransaction = async () => {
    const payload = {
      userId: user?.userId,
      rateId: total.rateId,
      fromDetails: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        total: total.quotePrice,
        ticker: total.senderCurrency,
        details: {
          paymentGateway: "Direct Transfer"
        }
      },
      toDetails: {
        userId: user?.userId,
        total: total.basePrice,
        ticker: `${TESTING ? "USDC_AVAX_FUJI" : "USDC_POLYGON_NXTB"}`,
        details: {
          name: selectedAccount.accountHolder,
          bank: selectedAccount.bankName,
          document_number: selectedAccount.NIT,
          account_name: selectedAccount.accountNumber,
        },
      },
    };
    setIsLoading(true);
    let result = await transactionService.createBankTransaction(payload);
    setIsLoading(false);
    if (!result.success) {
      modalInformation(
        "Ha ocurrido un error, por favor intenta de nuevo",
        false,
        "Error",
        IconProcess,
        "/charge"
      );
      return;
    }
    handleUploadFile(result.data?.data?.reference);
  };

  const handleUploadFile = async (reference: string) => {
    // ? guarda el archivo en la db
    await transactionService.uploadFile({
      file: fileToUpload,
      userId: user?.userId,
      transactionRef: reference,
    });
    modalInformation(
      "Tu transacción se encuentra en proceso",
      false,
      "Comprobante cargado con éxito ",
      IconSuccess,
      "/dashboard/activity",
      false,
      "Recuerda que se cargan tus USDC una vez se verifique la recepción de fondos. Este proceso puede tardar hasta 24 horas.",
      "Entendido"
    );
  };

  const handleGetFile = (event: any) => {
    if (!event.target.files[0]) return;
    // ? selecciona y guarda el archivo
    let file = event.target.files[0];
    if (event.target.files[0].size > 15000000) {
      alert("El archivo es demasiado grande, por favor selecciona otro");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileToUpload(reader.result);
    };
  };

  const selectAccount = (accountId: any) => {
    let account = bankAccounts.find((item: any) => item.accountId == accountId);
    onSelectAccount(account);
  }

  return (
    <>
      {name === "selection" && (
        <div className="swiper-slide__container">
          <Title
            title={"Transferencia Bancaria"}
            arrows={[true, false]}
            backClick={() => backClick()}
          />
          <form>
            <div>
              <label>Selecciona el banco</label>
              <select
                className="bx--text-input"
                onChange={(e) => selectAccount(e.target.value)}
              >
                <option value={""}>Seleccionar</option>
                {bankAccounts?.map((account: any) => (
                  <option value={account.accountId}>
                    {account.bankName}
                  </option>
                ))}
              </select>
            </div>
            <div className="bank_transfer_button">
              <Button
                isIcon={false}
                disable={!selectedAccount}
                label={"Siguiente"}
                onClick={frontClick}
              />
            </div>
          </form>
        </div>
      )}

      {name === "upload" && total.rate && (
        <>
          <div className="swiper-slide__container">
            <Title
              title="Transferencia Bancaria"
              arrows={[true, false]}
              backClick={() => backClick()}
            />
            <span className="charge__info-text">
              Realiza la transferencia desde una cuenta de la que seas titular.
            </span>
            <br />
            <span className="charge__info-text">
              Recuerda adjuntar el comprobante de la transferencia.
            </span>
            <div className="charge__comparative">
              <div className="charge__comparative_subtitle">
                <span>{"Información de pago"}</span>
              </div>
              {accountDetails &&
                accountDetails.map((item: any) => (
                  <Compare title={item.label} text={item.value} />
                ))}
            </div>
            <div className="charge__comparative">
              <Compare
                title={"Recarga:"}
                text={total.basePrice}
                subText={
                  " " + extractCurrencies(total.rate.name).baseCurrencyTicker
                }
              />
              <Compare
                title={"Tasa de cambio:"}
                text={total.rate.buyingPrice}
                subText={
                  " " +
                  extractCurrencies(total.rate.name).baseCurrencyTicker +
                  "/" +
                  extractCurrencies(total.rate.name).quoteCurrencyTicker
                }
              />
              <Compare
                title={"Valor de giro:"}
                text={total.rate.basePrice}
                subText={
                  " " + extractCurrencies(total.rate.name).quoteCurrencyTicker
                }
              />
              <Compare
                title={"Comisión:"}
                text={total.rate.commission}
                subText={
                  " " + extractCurrencies(total.rate.name).baseCurrencyTicker
                }
              />
              <Compare
                title={"Total a pagar:"}
                text={total.quotePrice}
                subText={
                  " " + extractCurrencies(total.rate.name).quoteCurrencyTicker
                }
              />
            </div>
            <br />
            <div>
              <form className="center">
                <div className="form__file">
                  <label htmlFor="checkBank__file" className="form__file-label">
                    {fileToUpload ? (
                      <>
                        <img className="charge__file" src={VIIO} alt={""} />
                        <p className="charge__info-text-file">
                          Archivo cargado exitosamente √<br />
                          pulsa siguiente para enviar
                        </p>
                        <input
                          type="file"
                          id="checkBank__file"
                          accept=".pdf,.jpg,.png"
                          onChange={handleGetFile}
                        />
                      </>
                    ) : (
                      <>
                        <img className="charge__file" src={FILE} alt={""} />
                        <p className="charge__info-text">
                          Toca aquí para cargar <br />
                          tu comprobante
                          <br />
                          (JPG, PNG ó PDF max. 15MB)
                        </p>
                        <input
                          type="file"
                          id="checkBank__file"
                          accept=".pdf,.jpg,.png"
                          onChange={handleGetFile}
                        />
                      </>
                    )}
                  </label>
                  {!fileToUpload && (
                    <div className="msg_alert">
                      <p className="warning">Archivo requerido</p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="withdraw__btn">
            <Button
              disable={!fileToUpload || isLoading}
              isIcon={false}
              label={"Siguiente"}
              onClick={() => createTransaction()}
            />
          </div>
        </>
      )}
    </>
  );
};

export default BankTransfer;
