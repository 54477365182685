
import { CheckResult, CheckType, Verifier } from "./Verfier";

class ClabeVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^.{18,18}$/
        if (!regex.test(value)) {
            return { message: "El CLABE debe contener 18 caracteres numericos", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const clabeVerifier = new ClabeVerifier();
export default clabeVerifier;
