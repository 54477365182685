import React from "react";
import typesymb from "../../assets/images/LOGO TEXT.svg";
import "./Header.scss";

interface HeaderProps {
    viio: boolean;
    onLogin?: boolean;
}

const Header: React.FC<HeaderProps> = ({ viio, onLogin }) => {
    return (
        <div className={`container ${viio ? 'viio' : ''}`}>
            {!onLogin && (
                <div className={`main`} >
                    <div className="main__img"><img src={typesymb} alt="VIIO Logo" width="90" /></div>
                </div>
            )}
        </div>
    );
};

export default Header;