import React, { useEffect, useState } from "react";
import "./BalanceModal.scss";
import { IonModal } from "@ionic/react";

interface ModalProps {
  open?: boolean;
  balance?: any;
  onClose?: any;
}

const BalanceModal: React.FC<ModalProps> = ({ open, balance, onClose }) => {
  return (
    <IonModal
      isOpen={open}
      className="balance-modal-container"
      onWillDismiss={onClose}
    >
      <div className="balance-modal-content">
        <div className="balance-row">
          <div className="balance-cell">
            <span className="balance-title">Total</span>
            <span>Balance con el que cuentas actualmente</span>
          </div>
          <div className="balance-cell end-cell">
            <span className="balance-total">
              ${balance.balanceInteger}
              {balance.balanceDecimal ? "," + balance.balanceDecimal : ""}
            </span>
            <span>USDC</span>
          </div>
        </div>
        <div className="balance-row">
          <div className="balance-cell">
            <span className="balance-title">Disponible</span>
            <span>Balance disponible para realizar transacciones</span>
          </div>
          <div className="balance-cell end-cell">
            <span className="balance-total">${balance.temporalBalance}</span>
            <span>USDC</span>
          </div>
        </div>
        <div className="wallet-back-buttton" onClick={onClose}>
          <span>Cerrar</span>
        </div>
      </div>
    </IonModal>
  );
};

export default BalanceModal;
