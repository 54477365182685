import { closeModal, triggerModal } from "../../store/modalReducer";
import { ILoginResponse } from "../../services/Services/LoginService/LoginModels";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IonPage } from "@ionic/react";
import Login from "../../components/Session/Login/Login";
import IconError from "../../assets/icon/config/WRONG.svg";
import IconAccount from "../../assets/images/pup_land/Account_bloqued.svg";
import IconPassword from "../../assets/images/pup_land/Wrong_password.svg";
import userService from "../../services/Services/UserService/UserService";
import IconInvalidAccess from "../../assets/images/pup_land/Invalid_access.svg";

const LoginPage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const loginResponse = (response: ILoginResponse) => {
        console.log(response);
        if (response.succesful) {
            checkTutorialStatus();
        } else {
            dispatch(
                triggerModal({
                    icon: getLoginErrors(response).icon,
                    title: getLoginErrors(response).title,
                    content: getLoginErrors(response).content,
                    label: "Entendido",
                    onClick: () => dispatch(closeModal()),
                })
            );
        }
    };

    const checkTutorialStatus = async () => {
        const { completed } = await userService.getTutorialStatus();
        if (!completed) {
            history.push("/tutorial", { replace: true });
            return;
        }
        history.push("dashboard/viio", { replace: true });
    };

    const changePage = (page: string) => {
        if (page === "register") history.push("/register");
        else history.push("/recover");
    };

    const getLoginErrors = (response: any) => {
        switch (response.description) {
            case "User not found":
                return {
                    icon: IconInvalidAccess,
                    title: "No estás registrado",
                    content: "Registrate con tu correo electrónico para usar VIIO. ¡Tomará menos de 5 minutos!",
                };
            case "Your account has been locked due to failing too many times":
                return {
                    icon: IconAccount,
                    title: "Cuenta bloqueada",
                    content:
                        "Has excedido el número de intentos para ingresar a tu cuenta. Por favor comunícate soporte@viio.me para desbloquear tu cuenta.",
                };
            case "User is blocked":
                return {
                    icon: IconAccount,
                    title: "Cuenta bloqueada",
                    content:
                        "Tu cuenta ha sido bloqueada. Por favor comunícate con soporte@viio.me para desbloquear tu cuenta. ",
                };
            case "Invalid password":
                return {
                    icon: IconPassword,
                    title: "Contraseña incorrecta",
                    content: response.attempts
                        ? `Te quedan ${3 - response.attempts > 1 ? `${3 - response.attempts} intentos` : `1 intento`}.`
                        : "Te quedan menos de 3 intentos.",
                };
            default:
                return {
                    icon: IconError,
                    title: "Error temporal",
                    content: "VIIO no esta disponible temporalmente.",
                };
        }
    };

    return (
        <IonPage>
            <Login callback={loginResponse} changePage={changePage} />
        </IonPage>
    );
};

export default LoginPage;
