import { NequiTransaction, PSETransaction, TransactionResult } from "./TransactionModels";
import { TESTING, TRANSACTION_SERVICE_BACKEND } from "../../BackendServicesExports";
import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { Publisher } from "../../Publisher";
import axios from "axios";

const EXTERNAL_CALL_TIMESTAMP = "external_call_timestamp";
const DELAY_TIME_IN_SECONDS = 10000

class TransactionService extends Publisher {
    private transactionServiceUrl: string;

    constructor(transactionServiceUrl: string) {
        super();
        this.transactionServiceUrl = transactionServiceUrl;
    }

    public async getBalance() {
        try {
            const balance: any = await axios.get(`${this.transactionServiceUrl}/v1/balance/Fireblocks/${TESTING ? "USDC_AVAX_FUJI" : "USDC_POLYGON_NXTB"}`, buildAuthorizationConfig());
            return { success: true, data: balance.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async getTransactions(
        offset: string | number = 0,
        limit: string | number = 10,
        type: string = "",
        status: string = "",
        referece: string = "",
        startDate: string = "",
        endDate: string = ""
    ) {
        let query = `/v1/transaction?limit=${limit}&offset=${offset}`;
        if (type) query += `&transactionType=${type}`;
        if (status) query += `&transactionStatus=${status}`;
        if (referece) query += `&reference=${referece}`;
        if (startDate) query += `&startDate=${startDate}`;
        if (endDate) query += `&endDate=${endDate}`;
        try {
            const transactions: any = await axios.get(
                `${this.transactionServiceUrl}${query}`,
                buildAuthorizationConfig()
            );
            return { success: true, data: transactions.data.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async getInstitutions(): Promise<TransactionResult> {
        try {
            const institutions: any = await axios.get(
                `${this.transactionServiceUrl}/v1/wompi/financialInstitutions`,
                buildAuthorizationConfig()
            );
            console.log(institutions.data);
            return { success: true, data: institutions.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async getPaymentezInstitutions(): Promise<TransactionResult> {
        try {
            const institutions: any = await axios.get(
                `${this.transactionServiceUrl}/v1/paymentez/banks/PSE`,
                buildAuthorizationConfig()
            );
            return { success: true, data: institutions.data.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createNequiTransaction(
        data: NequiTransaction
    ): Promise<TransactionResult> {
        try {
            await axios.post(
                `${this.transactionServiceUrl}/v1/wompi/nequi/transactions`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true };
        } catch (error: any) {
            return {
                success: false,
                description: error?.response?.data?.message ?? error,
            };
        }
    }

    public async createWompiPSETransaction(
        data: PSETransaction
    ): Promise<TransactionResult> {
        try {
            let transaction: any = await axios.post(
                `${this.transactionServiceUrl}/v1/wompi/pse/transactions`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: transaction.data.redirectionUrl };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createPaymentezPSETransaction(
        data: any
    ): Promise<TransactionResult> {
        try {
            let transaction: any = await axios.post(
                `${this.transactionServiceUrl}/v1/paymentez/transfer/PSE`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: transaction.data.redirectionUrl };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createBankTransferTransaction(
        data: any
    ): Promise<TransactionResult> {
        try {
            let transaction: any = await axios.post(
                `${this.transactionServiceUrl}/v1/bankTransfer/transactions`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: transaction.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async uploadFile(data: any): Promise<TransactionResult> {
        try {
            let transaction: any = await axios.post(
                `${this.transactionServiceUrl}/v1/upload-receipt`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: transaction.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createBankTransaction(data: any): Promise<TransactionResult> {
        try {
            let transaction: any = await axios.post(
                `${this.transactionServiceUrl}/v1/deposit-receipt`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: transaction.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createWithdraw(
        data: any,
        type: string
    ): Promise<TransactionResult> {
        try {
            let response: any = await axios.post(
                `${this.transactionServiceUrl}/v1/withdraw/${type}`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createExternalWithdraw(
        data: any,
        type: string
    ): Promise<TransactionResult> {
        try {
            let response: any = await axios.post(
                `${this.transactionServiceUrl}/v1/externalWithdraw/${type}`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async createViioTransfer(data: any): Promise<TransactionResult> {
        try {
            let response: any = await axios.post(
                `${this.transactionServiceUrl}/v1/transfer`,
                data,
                buildAuthorizationConfig()
            );
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async updateExternalDeposit(): Promise<any> {
        if (!localStorage.getItem(EXTERNAL_CALL_TIMESTAMP)) localStorage.setItem(EXTERNAL_CALL_TIMESTAMP, new Date().getTime() + "");
        if (parseFloat(localStorage.getItem(EXTERNAL_CALL_TIMESTAMP) + "") >= (new Date().getTime() - DELAY_TIME_IN_SECONDS)) return;
        try {
            localStorage.setItem(EXTERNAL_CALL_TIMESTAMP, new Date().getTime() + "");
            let response: any = await axios.post(
                `${this.transactionServiceUrl}/v1/external`,
                {},
                buildAuthorizationConfig()
            );
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        } finally {
            localStorage.setItem(EXTERNAL_CALL_TIMESTAMP, new Date().getTime() + "");
        }
    }

    public async downloadReceipt(transactionRef: any): Promise<any> {
        try {
            let response: any = await axios.post(
                `${this.transactionServiceUrl}/v1/download-receipt`,
                { transactionRef },
                buildAuthorizationConfig()
            );
            return { success: true, data: response.data };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }

    public async getBankAccounts(): Promise<any> {
        try {
            let response: any = await axios.get(`${this.transactionServiceUrl}/v1/viio-bank-accounts`, buildAuthorizationConfig());
            return { success: true, data: response.data?.accounts };
        } catch (error: any) {
            return { success: false, description: error.data };
        }
    }
}

const transactionService = new TransactionService(TRANSACTION_SERVICE_BACKEND);
export default transactionService;
