import { FC, useState, useEffect } from "react";

import { CheckType } from "../../../../services/Verifiers/Verfier";
import Validator from "../../../Validator/Validator";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import walletVerifier from "../../../../services/Verifiers/WalletVerifier";

interface ETHProps {
  handleGetData: (data: any) => any;
}

const ETH: FC<ETHProps> = ({ handleGetData }) => {
  const [wallet, setWallet] = useState<any>({ type: CheckType.INIT, message: ""});
  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit])

  const handleWallet = (target: any) => {
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "la direccion de la wallet");
    else checker = walletVerifier.check(target.value);
    setWallet(checker);
    saveSubmit(target.value, target.name)
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({...submit})
  }

  const checkData = () => {
    const options = [wallet];
    const isValid = options.every((option: any) => option.type === CheckType.VALID);
    handleGetData({
      isValid: isValid,
      data: { ...submit, network_crypto: 'Polygon (Matic)' }
    })
  }

  return (
    <form>
      <div>
        <label>{`Red`}</label>
        <input
          disabled
          type="text"
          value={`Polygon (Matic)`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*Direccion de Wallet`}</label>
        <input
          name={"address_wallet"}
          onChange={(e) => handleWallet(e.target)}
        />
        <Validator
          showAlert={wallet.type !== CheckType.VALID}
          message={wallet.message}
          type={wallet.type}
        />
      </div>
    </form>
  );
};

export default ETH;
