import sessionService from "../Services/SessionService/SessionService";

export function buildAuthorizationConfig(token?: string) {
    const authHeader = `Bearer ${token ?? sessionService.getTokenFromStorage()}`
    return {
        headers: {
            Authorization: authHeader!,
        },
    };
}
