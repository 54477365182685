import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import "./Balance.scss";
import BalanceModal from "../BalanceModal/BalanceModal";
import icon from "../../../assets/icon/config/DROPDOWN.svg";
import showPasswordImg from "../../../assets/icon/password/show-password-light.svg";
import hidePasswordImg from "../../../assets/icon/password/hide-password-light.svg";

interface BalanceProps {}

const Balance: React.FC<BalanceProps> = () => {
    const boxRef = useRef<any>();

    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [avaiableBalance, setAvailableBalance] = useState<number>(0);
    const [balanceInteger, setBalanceInteger] = useState<number>(0);
    const [balanceDecimal, setBalanceDecimal] = useState<string>("00");
    const [showBalanceModal, setShowBalanceModal] = useState<boolean>(false);
    const [showBalance, setShowBalance] = useState<boolean>(true);

    useLayoutEffect(() => {
        // gsap.fromTo(
        //     boxRef.current,
        //     {
        //         opacity: 0,
        //         y: "-5%",
        //     },
        //     {
        //         opacity: 1,
        //         y: 0,
        //         duration: 2,
        //     }
        // );
    });

    useEffect(() => {
        getBalance();
    });

    useEffect(() => {
        if (totalBalance > 0) {
            setBalanceInteger(Math.floor(totalBalance));
            setBalanceDecimal((totalBalance % 1).toString().substring(2, 4) ?? "00");
        }
    }, [totalBalance]);

    const getBalance = async () => {
        let result: any = await transactionService.getBalance();
        if (result.success) {
            setTotalBalance(result.data.totalBalance);
            setAvailableBalance(result.data.availableBalance);
        }
    };

    return (
        <>
            <div className="balance">
                <div className="balance__icon">
                    <span>Total:</span>
                    <img
                        src={!showBalance ? hidePasswordImg : showPasswordImg}
                        alt=""
                        onClick={() => setShowBalance(!showBalance)}
                    ></img>
                </div>
                <div className="balance__data" ref={boxRef}>
                    <i>$</i> {showBalance ? <>{balanceInteger > 0 ? balanceInteger : "0"}</> : "·····"}
                    <span>
                        {showBalance ? (
                            <>{balanceDecimal ? `,${balanceDecimal}` : ``}</>
                        ) : (
                            <>{balanceDecimal ? ",··" : ""}</>
                        )}
                    </span>
                </div>
                
                <div className="balance__available">
                    <span>Disponible: $ {showBalance ? avaiableBalance.toFixed(2) : "·····"} USDC</span> 
                    <div className="show-modal-icon" onClick={() => setShowBalanceModal(true)}>
                        <img src={icon} />
                    </div>
                </div> 
            </div>
            <BalanceModal
                open={showBalanceModal}
                balance={{ balance: totalBalance, temporalBalance: avaiableBalance, balanceInteger, balanceDecimal }}
                onClose={() => setShowBalanceModal(false)}
            />
        </>
    );
};

export default Balance;
