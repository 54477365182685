import { ChangeEvent, FC, useState } from "react";
import Validator from "../Validator/Validator";
import { CheckResult, CheckType } from "../../services/Verifiers/Verfier";
import passwordRules from "../../services/Verifiers/PasswordVerifier";
import nonEmptyVerifier from "../../services/Verifiers/NonEmptyVerifier";
import showPasswordImg from "../../assets/icon/password/show-password.svg";
import hidePasswordImg from "../../assets/icon/password/hide-password.svg";
import "./PasswordInput.scss";

interface PasswordInputProps {
  label?: string;
  handleChange?: any;
  hasValidator?: boolean
  palceholder?: string,
}

export const PasswordInput: FC<PasswordInputProps> = ({
  label = "Contraseña",
  hasValidator = true,  
  handleChange = () => null,
  palceholder
}) => {
  const [alert, setAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value
    let field = {
      value: password,
      isValid: true,
    };
    if (hasValidator) {
      let confirmation: any;
      if (password === "") confirmation = nonEmptyVerifier.check(e.target.value, "una contraseña");
      else confirmation = passwordRules.check(e.target.value);
      setAlert(confirmation);
      field.isValid = confirmation.type === CheckType.VALID;
    }
    handleChange(field);
  };

  return (
    <>
      <div>
        <label>{label}</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            required
            onChange={handleChangeField}
            placeholder={palceholder}
          />
          <img
            className="password-icon"
            src={showPassword ? showPasswordImg : hidePasswordImg}
            alt=""
            onClick={() => setShowPassword(!showPassword)}
          ></img>
        </div>
        {hasValidator && (
          <Validator
            showAlert={alert.type !== CheckType.VALID}
            message={alert.message}
            type={alert.type}
          />
        )}
      </div>
    </>
  );
};

export default PasswordInput;
