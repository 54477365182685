import React, { ChangeEvent, useState } from "react";
import Button from "../../../Buttons/Button/Button";
import Title from "../../../Title/Title";
import PasswordInput from "../../../PasswordInput/PasswordInput";
import Validator from "../../../Validator/Validator";
import { CheckResult, CheckType } from "../../../../services/Verifiers/Verfier";
import passwordRules from "../../../../services/Verifiers/PasswordVerifier";
import userService from "../../../../services/Services/UserService/UserService";
import Modal from "../../../Modal/Modal";
import { useHistory } from "react-router";
import IconGear from "../../../../assets/icon/config/GEAR.svg";

interface PasswordUpdateProps {
  backClick: any;
  setShowModal: any;
  setModalResponse: any;
}

const PasswordUpdate: React.FC<PasswordUpdateProps> = ({ backClick, setModalResponse, setShowModal }) => {
  const history = useHistory();

  const [password, setPassword] = useState<any>({ value: "", isValid: false });
  const [newPassword, setNewPassword] = useState<any>({ value: "", isValid: false });
  const [passwordConfirmationAlert, setPasswordConfirmationAlert] =
    useState<CheckResult>({ type: CheckType.INIT, message: "" });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleNewPassword = (field: any) => {
    setNewPassword({ ...field });
  };

  const handlePassword = (field: any) => {
    setPassword({ ...field });
  };

  const handlePasswordConfirmation = (field: any) => {
    setPasswordConfirmationAlert({ ...passwordRules.match(newPassword.value, field.value) });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await userService.verifyAndUpdatePassword({ oldPassword: password.value, newPassword: newPassword.value });
      setShowModal(true);
      if (!response.succesful) {
        let isWrongPassword = response.error === "WrongPassword";
        setModalResponse({
          title: isWrongPassword ? "Contraseña incorrecta" : "Lo sentimos",
          content: isWrongPassword ? "La contraseña es incorrecta" : "No se pudo completar el cambio de contraseña",
        });
        return;
      }
      setModalResponse({
        icon: IconGear,
        title: "Cambio exitoso",
        content: "Contraseña actualizada con exito",
        // onClick: () => history.push("/dashboard/config")
      });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="swiper-slide__container">
        <Title
          title={"Actualizar contraseña"}
          arrows={[true, false]}
          backClick={backClick}
        />
        <form>
          <PasswordInput
            label="Contraseña actual"
            handleChange={handlePassword}
          />
          <PasswordInput
            label="Nueva contraseña"
            handleChange={handleNewPassword}
          />
          <PasswordInput
            hasValidator={false}
            label="Confirmar nueva contraseña"
            handleChange={handlePasswordConfirmation}
          />
          <Validator
            showAlert={passwordConfirmationAlert.type !== CheckType.VALID}
            message={passwordConfirmationAlert.message}
            type={passwordConfirmationAlert.type}
          />
        </form>
      </div>
      <div className="security__btn">
        <Button
          isIcon={false}
          label="Actualizar"
          //   disable={!password.isValid || !newPassword.isValid || passwordConfirmationAlert.type !== CheckType.VALID}
          disable={passwordConfirmationAlert.type !== CheckType.VALID}
          onClick={() => handleSubmit()}
        ></Button>
      </div>
    </>
  );
};

export default PasswordUpdate;
