import React, { useEffect, useRef, useState } from "react";
import BankTransfer, { BankTransferStates } from "../PaymentMethods/BankTransfer/BankTransfer";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonContent } from "@ionic/react";
import { Navigation } from "swiper";
import { ITotal } from "../PaymentMethods/PaymentMethodsModels";
import exchangeRateService from "../../../services/Services/ExchangeRateService/ExchangeRateService";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import invalidAccessIcon from "../../../assets/images/pup_land/Invalid_access.svg";
import PseTransaction from "../PaymentMethods/PSE/Steps/PseTransaction";
import PseInformation from "../PaymentMethods/PSE/Steps/PseInformation";
import userService from "../../../services/Services/UserService/UserService";
import kycService from "../../../services/Services/KycService/KycService";
import ButtonLink from "../../Buttons/ButtonLink/ButtonLink";
import SavingIcon from "../../../assets/images/pup_land/Saving_dollars.svg";
import PairInput from "../PairInput/PairInput";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import "swiper/css";
import "./Charge.scss";
import { buildAccountDetails, defaultAccountOptions } from "./ChargeDefinitions";
import { validateKYCSuccessAndCompletedStatus } from "../../../services/Tools/KYCVerifierTools";

export interface ImodalInformation {
    (
        message: string,
        disable: boolean,
        title?: string,
        icon?: any,
        page?: string,
        hideButtons?: boolean,
        secondaryContent?: string,
        label?: string
    ): void;
}

interface ChargeProps {
    resetSwiper: boolean;
    home(): void;
    modalInformation: ImodalInformation;
}

const Charge: React.FC<ChargeProps> = ({ resetSwiper, home, modalInformation }) => {
    const swiperNavPrevRef = useRef<any>();
    const swiperNavNextRef = useRef<any>();

    const [swiperInstance, setSwiperInstance] = useState<any>();

    const [total, setTotal] = useState<ITotal>({
        rate: {},
        rateId: 0,
        basePrice: 0,
        quotePrice: 0,
    });
    const [rate, setRate] = useState<any>();
    const [rateId, setRateId] = useState<number>(0);
    const [commission, setCommission] = useState<number>(0);
    const [exchangedPrice, setExchangedPrice] = useState<string>("0");
    const [paymentMethod, setPaymentMethod] = useState<string>("");
    const [user, setUser] = useState<any>();

    const [limit, setLimit] = useState<any>([]);
    const [limitAlert, setLimitAlert] = useState<string>("");

    const [bankDetails, setBankDetails] = useState<any>(null);

    const [documentType, setDocumentType] = useState<any>("null");
    const [document, setDocument] = useState<any>(0);
    const [bankName, setBankName] = useState<any>("");
    const [bank, setBank] = useState<any>("");

    // bank tranfer
    const [bankAccounts, setBankAccounts] = useState<any>([])
    const [selectedAccount, setSelectedAccount] = useState<any>()
    const [accountDetails, setAccountDetails] = useState<any>([{ label: "sss", value: "sss" }]);

    useEffect(() => {
        if (resetSwiper) {
            changeSlide(swiperInstance, "init");
        }
    }, [resetSwiper]);

    useEffect(() => {
        setAccountDetails(buildAccountDetails(selectedAccount))
    }, [selectedAccount]);

    useEffect(() => {
        getCredentials();
        getRate();
        getTransactionLimits();
        getAccounts();
        transactionService.updateExternalDeposit();
    }, []);

    useEffect(() => {
        checkLimits();
    }, [total, limit]);

    const handleOpeningModal = () => {
        modalInformation(
            "Recuerda que se cargan tus USDC una vez se verifique la recepción de fondos. El proceso puede tardar hasta 24 horas. ",
            false,
            "Compra con transferencia bancaria ",
            SavingIcon,
            "close",
            false,
            "*Realiza la transferencia desde una cuenta de la que seas titular",
            "Entendido"
        );
    };

    const getTransactionLimits = async () => {
        const response = await exchangeRateService.getAssetsLimit();
        const limit = response.find((item: any) => item["Assets.ticker"] == "COP");
        setLimit(limit);
    };

    const checkLimits = () => {
        if (total?.basePrice < limit?.minLimit) {
            setLimitAlert(`Monto mínimo: ${limit?.minLimit} USDC`);
            return;
        }
        if (total?.basePrice > limit?.maxLimit) {
            setLimitAlert(`Monto maximo: ${limit?.maxLimit} USDC`);
            return;
        }
        setLimitAlert("");
    };

    const getAccounts = async () => {
        let { data } = await transactionService.getBankAccounts();
        setBankAccounts(data || defaultAccountOptions);
    }

    const getTotal = (total: any) => {
        setTotal({ rate, rateId, ...total });
    };

    const getRate = async () => {
        const rate = await exchangeRateService.getRate("USDC-COP");
        if (rate) {
            setRate(rate);
            setRateId(rate.rateId);
            setCommission(rate.commission);
            setExchangedPrice(rate.buyingPrice);
        }
    };

    

    const checkKycStatus = async () => {
        const kycStatus = await kycService.verifyKycStatus();
        if (!validateKYCSuccessAndCompletedStatus(kycStatus))
            modalInformation(
                "Realiza la verificación de tu identidad para usar VIIO. Toma solo unos minutos.",
                false,
                "No estás verificado",
                invalidAccessIcon,
                "/dashboard/config"
            );
    };

    const changeSlide = (swaperInstance: any, event: string) => {
        switch (event) {
            case "next":
                swaperInstance.slideNext(500);
                break;
            case "prev":
                swaperInstance.slidePrev(500);
                break;
            case "init":
                swaperInstance.slideTo(0);
                break;
        }
    };

    const getCredentials = async () => {
        const response: any = await userService.getUserCredentials();
        const user = response?.data;
        user.documents = [];
        if (user.identificationObject) {
            user?.documents?.push({ ...user.identificationObject });
        }
        if (user.identificationObject?.metamap) {
            user?.documents?.unshift({ ...user.identificationObject?.metamap });
        }
        setUser(user);
    };

    const showPaymentMethod = () => {
        switch (paymentMethod) {
            case "BANKTRANSFER":
                return (
                    <BankTransfer
                        bankAccounts={bankAccounts}
                        onSelectAccount={(account: any) => setSelectedAccount(account)}
                        selectedAccount={selectedAccount}
                        total={total}
                        user={user}
                        modalInformation={modalInformation}
                        name={BankTransferStates.SELECTION}
                        backClick={() => {
                            changeSlide(swiperInstance, "prev");
                        }}
                        frontClick={() => {
                            changeSlide(swiperInstance, "next");
                        }}
                    />
                );
            case "PSE":
                return (
                    <PseInformation
                        user={user}
                        backClick={() => {
                            changeSlide(swiperInstance, "prev");
                        }}
                        frontClick={() => {
                            changeSlide(swiperInstance, "next");
                        }}
                        setDocumentType={setDocumentType}
                        setDocument={setDocument}
                        setBankName={setBankName}
                        setBank={setBank}
                    />
                );
        }
    };

    const showPaymentMethodConfirmation = () => {
        switch (paymentMethod) {
            case "BANKTRANSFER":
                return (
                    <BankTransfer
                        accountDetails={accountDetails}
                        selectedAccount={selectedAccount}
                        total={total}
                        user={user}
                        modalInformation={modalInformation}
                        name={BankTransferStates.UPLOAD}
                        backClick={() => {
                            changeSlide(swiperInstance, "prev");
                        }}
                        frontClick={() => {
                            changeSlide(swiperInstance, "next");
                        }}
                    />
                );
            case "PSE":
                return (
                    <PseTransaction
                        stepIndex={swiperInstance.activeIndex}
                        total={total}
                        modalInformation={modalInformation}
                        backClick={() => {
                            changeSlide(swiperInstance, "prev");
                        }}
                        documentType={documentType}
                        document={document}
                        bankName={bankName}
                        bank={bank}
                    />
                );
        }
    };

    return (
        <IonContent className="ion-content">
            <Swiper
                modules={[Navigation]}
                navigation={{
                    prevEl: swiperNavPrevRef.current,
                    nextEl: swiperNavNextRef.current,
                }}
                slidesPerView={1}
                spaceBetween={400}
                allowTouchMove={false}
                onInit={(swiper: any) => {
                    swiper.params.navigation.prevEl = swiperNavPrevRef.current;
                    swiper.params.navigation.nextEl = swiperNavNextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <Title title={"Comprar USDC"} arrows={[true, false]} backClick={home} />
                        <PairInput info={0} exchangedPrice={exchangedPrice} commission={commission} total={getTotal} rateDecimals={rate?.quoteCurrency?.details?.decimals || 2} />
                    </div>
                    <div className="msg_alert">
                        <span className="warning">{limitAlert}</span>
                    </div>
                    <div className="charge__btn">
                        <Button
                            isIcon={false}
                            disable={limitAlert != ""}
                            label={"Siguiente"}
                            onClick={() => {
                                checkKycStatus();
                                changeSlide(swiperInstance, "next");
                            }}
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <Title
                            title="Método de pago"
                            arrows={[true, false]}
                            backClick={() => {
                                changeSlide(swiperInstance, "prev");
                            }}
                        />
                        <ButtonLink
                            icon="&#xe901;"
                            mode={""}
                            label={"Transferencía bancaria"}
                            outline="both"
                            onClick={() => {
                                handleOpeningModal();
                                setPaymentMethod("BANKTRANSFER");
                                changeSlide(swiperInstance, "next");
                            }}
                        />
                        <ButtonLink
                            icon="&#xe911;"
                            mode={""}
                            label={"PSE"}
                            outline="bottom"
                            onClick={() => {
                                setPaymentMethod("PSE");
                                changeSlide(swiperInstance, "next");
                            }}
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>{showPaymentMethod}</SwiperSlide>
                <SwiperSlide>{showPaymentMethodConfirmation}</SwiperSlide>
            </Swiper>
        </IonContent>
    );
};

export default Charge;
