import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { EXCHANGE_RATE_SERVICE, TESTING } from "../../BackendServicesExports";
import { Publisher } from "../../Publisher";
import axios from "axios";

export class ExchangeRateService extends Publisher {

    private exchangeRateServiceUrl: string;
    private _validUntil = Date.now();
    private rates: any[];

    constructor(exchangeRateServiceUrl: string) {
        super();
        this.rates = [];
        this.exchangeRateServiceUrl = exchangeRateServiceUrl;
    }

    public get validUntil() {
        return this._validUntil;
    }

    public async getRates() {
        if (this.rates.length > 0) {
            return this.rates;
        }
        return await this.fetchRates();
    }

    public async getRate(pairName: string) {
        if (!this.rates.length) {
            await this.fetchRates();
        }
        return this.rates.find((rate: any) => rate.name === pairName);
    }

    public async updateRates() {
        await this.fetchRates();
    }

    private async fetchRates() {
        try {
            let rates: any = await axios.get(`${this.exchangeRateServiceUrl}/v1/rates?latest={}&baseCurrencyId=$${TESTING ? "USDC_AVAX_FUJI" : "USDC_POLYGON_NXTB"}`, buildAuthorizationConfig());
            this.rates = rates.data.result;
            return this.rates;
        } catch (error: any) {
            return [];
        }
    }

    public async getAssetsLimit() {
        try {
            let response: any = await axios.get(`${this.exchangeRateServiceUrl}/v1/assets/limits`, buildAuthorizationConfig());
            return response.data?.result;
        } catch (error: any) {
            return [];
        }
    }

    public async getAssets() {
        try {
            let response: any = await axios.get(`${this.exchangeRateServiceUrl}/v1/assets`, buildAuthorizationConfig());
            return response.data?.result;
        } catch (error: any) {
            return [];
        }
    }

    public async getPairs() {
        try {
            let response: any = await axios.get(`${this.exchangeRateServiceUrl}/v1/pairs`, buildAuthorizationConfig());
            return response.data?.result;
        } catch (error: any) {
            return [];
        }
    }

    public async getPair(pairId: string | number) {
        try {
            let response: any = await axios.get(`${this.exchangeRateServiceUrl}/v1/pairs`, buildAuthorizationConfig());
            return response.data?.result;
        } catch (error: any) {
            return [];
        }
    }
}

const exchangeRateService = new ExchangeRateService(EXCHANGE_RATE_SERVICE);

export default exchangeRateService;
