import { CheckResult, CheckType, Verifier } from "./Verfier";

class NonEmptyVerifier implements Verifier<string>{

    public check(input: string, message: string): CheckResult {
        if (input.length === 0)
            return { message: `Digita ${message}`, type: CheckType.WARNING };
        return { message: "", type: CheckType.VALID };
    }

}

const nonEmptyVerifier = new NonEmptyVerifier();
export default nonEmptyVerifier;
