import { useState, useEffect, FC } from "react";

import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import Validator from "../../../Validator/Validator";
import accountVerifier from "../../../../services/Verifiers/AccountVerifier";
import bsbCodeVerifier from "../../../../services/Verifiers/BsbCodeVerifier";
import digitVerfier from "../../../../services/Verifiers/DigitVerifier";
import zipCodeVerifier from "../../../../services/Verifiers/ZipCodeVerifier";

interface AUDProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const AUD: FC<AUDProps> = ({ handleGetData, defaultValues }) => {
  const [bsbCode, setBsbCode] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [accountNumber, setAccountNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [country, setCountry] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [state, setState] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [city, setCity] = useState<any>({ type: CheckType.INIT, message: "" });
  const [address, setAdress] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [zipCode, setZipCode] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });

  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit]);

  const handleBsb = (target: any) => {
    target.value = /^[0-9]{0,6}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el BSB Code");
    else checker = bsbCodeVerifier.check(target.value);
    setBsbCode(checker);
    saveSubmit(target.value, target.name);
  };

  const handleAccountNumber = (target: any) => {
    target.value = /^[0-9]{0,9}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el numero de cuenta");
    else checker = accountVerifier.checkAUD(target.value);
    setAccountNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const handleNonEmpty = (target: any, setChecker: any, message: string) => {
    let checker = nonEmptyVerifier.check(target.value, message);
    setChecker(checker);
    saveSubmit(target.value, target.name);
  };

  const handleZipCode = (target: any) => {
    target.value = /^[0-9]+$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el Código Postal");
    else checker = zipCodeVerifier.check(target.value);
    setZipCode(checker);
    saveSubmit(target.value, target.name);
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({ ...submit });
  };

  const checkData = () => {
    const options = [
      bsbCode,
      accountNumber,
      country,
      state,
      city,
      address,
      zipCode,
    ];
    const isValid = options.every(
      (option: any) => option.type === CheckType.VALID
    );
    handleGetData({
      isValid: isValid,
      data: {
        ...submit,
        accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
      },
    });
  };
  return (
    <form>
      <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          type="text"
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*BSB Code`}</label>
        <input
          value={submit.bsc_code}
          name={"bsbCode"}
          onChange={(e) => handleBsb(e.target)}
        />
        <Validator
          showAlert={bsbCode.type !== CheckType.VALID}
          message={bsbCode.message}
          type={bsbCode.type}
        />
      </div>
      <div>
        <label>{`*Número de cuenta`}</label>
        <input
          required
          value={submit.accountNumber}
          name="accountNumber"
          onChange={(e) => handleAccountNumber(e.target)}
        />
        <Validator
          showAlert={accountNumber.type !== CheckType.VALID}
          message={accountNumber.message}
          type={accountNumber.type}
        />
      </div>
      <div>
        <label>{`*Pais`}</label>
        <input
          name={"country"}
          onChange={(e) => handleNonEmpty(e.target, setCountry, "el pais")}
        />
        <Validator
          showAlert={country.type !== CheckType.VALID}
          message={country.message}
          type={country.type}
        />
      </div>
      <div>
        <label>{`*Ciudad`}</label>
        <input
          name={"city"}
          onChange={(e) => handleNonEmpty(e.target, setCity, "la ciudad")}
        />
        <Validator
          showAlert={city.type !== CheckType.VALID}
          message={city.message}
          type={city.type}
        />
      </div>
      <div>
        <label>{`*Direccion`}</label>
        <input
          name={"address"}
          onChange={(e) => handleNonEmpty(e.target, setAdress, "la direccion")}
        />
        <Validator
          showAlert={address.type !== CheckType.VALID}
          message={address.message}
          type={address.type}
        />
      </div>
      <div>
        <label>{`*Estado`}</label>
        <input
          name={"state"}
          onChange={(e) => handleNonEmpty(e.target, setState, "el estado")}
        />
        <Validator
          showAlert={state.type !== CheckType.VALID}
          message={state.message}
          type={state.type}
        />
      </div>
      <div>
        <label>{`*Código postal`}</label>
        <input
          value={submit.zipcode}
          name={"zipcode"}
          onChange={(e) => handleZipCode(e.target)}
        />
        <Validator
          showAlert={zipCode.type !== CheckType.VALID}
          message={zipCode.message}
          type={zipCode.type}
        />
      </div>
    </form>
  );
};

export default AUD;
