import { FC } from "react";
import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import Tutorial from "../../components/Tutorial/Tutorial";

const TutorialPage: FC = () => {
  return (
    <IonPage>
      <Tutorial />
    </IonPage>
  );
};

export default TutorialPage;
