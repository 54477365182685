import { IonPage } from "@ionic/react";
import Header from "../../components/Header/Header";
import ResetPasssord from "../../components/Session/ResetPassword/ResetPassword";

const ResetPasswordPage: React.FC = () => {
  return (
    <IonPage>
      <Header viio={false} />
      <ResetPasssord />
    </IonPage>
  );
};

export default ResetPasswordPage;
