import React from "react";
import "./Compare.scss";

interface CompareProps {
    title: string;
    text: string | number;
    link?: string | undefined;
    subText?: string | number;
    style?: string;
    little?: string | undefined;
}

const Compare: React.FC<CompareProps> = ({ title, link, text, subText, style, little }) => {
    const renderLinkParagraph = (link: string | undefined, text: string | number, subText: string | number | undefined) => {
        return (
            <p className="compare__text">
                {link ? <a href={link}>{text}</a> : null}
                {subText ? renderSubText(subText) : null}
            </p>
        );
    }

    const renderSubText = (subText: string | number) => {
        return (
            <span className="compare_subtext">
                {subText}
            </span>
        )
    }

    const renderTextParagraph = (text: string | number, subText: string | number | undefined) => {
        return (
            <p className="compare__text">
                {text}
                {subText ? renderSubText(subText) : null}
            </p>
        )
    }

    return (
        <div className={"compare style" + little ? " little" : ""}>
            <p className="compare__title">{title} </p>
            {
                style === 'link' ? renderLinkParagraph(link, text, subText) : renderTextParagraph(text, subText)
            }
        </div>

    );
};

export default Compare;