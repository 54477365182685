
import { CheckResult, CheckType, Verifier } from "./Verfier";

class UkSortCodeVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^[0-9]{6}$/ // ^.{6,6}$
        if (!regex.test(value)) {
            return { message: "El UK Sort code debe contener 6 caracteres numericos", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const ukSortCodeVerifier = new UkSortCodeVerifier();
export default ukSortCodeVerifier;
