import { IonIcon } from '@ionic/react';
import React from "react";
import "./Button.scss";

interface ButtonProps {
    isIcon?: boolean;
    icon?: string;
    label?: string;
    disable?: boolean;
    light?: boolean;
    onClick?: any;
    ref?: any;
}

const Button: React.FC<ButtonProps> = ({ isIcon, icon, label, disable, light, onClick, ref }) => {

    const showIcon = () => {
        return isIcon ?
            <IonIcon icon={icon}></IonIcon> :
            <p className="button__label">{label}</p>
    };

    return (
        <div className={"button" + (light ? " button--light" : "") + (disable ? " button--disable" : "")} onClick={onClick} ref={ref}>
            {showIcon()}
        </div>
    );
};

export default Button;