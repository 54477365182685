import { CheckResult, CheckType, Verifier } from "./Verfier";

class PhoneVerifier implements Verifier<string>{

    public check(password: string): CheckResult {
        if (password.length === 0)
            return { message: "Digita tu No. celular", type: CheckType.WARNING };
        if (password.length === 10)
            return { message: "", type: CheckType.VALID };
        return { message: "El No. celular debe contener 10 caracteres numéricos", type: CheckType.DANGER };
    }
}

const phoneVerifier = new PhoneVerifier();
export default phoneVerifier;
