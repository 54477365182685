
import { CheckResult, CheckType, Verifier } from "./Verfier";

class AccountVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^.{4,20}$/
        if (!regex.test(value)) {
            let message = "El numero es demasiado corto"
            if (value.length > 20) message = "El numero es demasiado largo"
            return { message: message, type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }

    public checkCAD(value: string): CheckResult {
        const regex: RegExp = /^[0-9]{7,12}$/
        if (!regex.test(value)) return { message: "Numero de cuenta invalido", type: CheckType.DANGER };
        return { message: "", type: CheckType.VALID };
    }

    public checkGBP(value: string): CheckResult {
        const regex: RegExp = /^[0-9]{8}$/
        if (!regex.test(value)) return { message: "El número de cuenta debe contener 8 dígitos numéricos", type: CheckType.DANGER };
        return { message: "", type: CheckType.VALID };
    }

    public checkAUD(value: string): CheckResult {
        const regex: RegExp = /^[0-9]{4,9}$/
        if (!regex.test(value)) return { message: "El número de cuenta debe contener entre 4 y 9 dígitos numéricos", type: CheckType.DANGER };
        return { message: "", type: CheckType.VALID };
    }
}

const accountVerifier = new AccountVerifier();
export default accountVerifier;
