import axios from "axios";
import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { USER_MANAGER_SERVICE_BACKEND } from "../../BackendServicesExports";

class UserAuthMethodService {

    private serviceUrl: string;

    constructor(serviceUrl: string) {
        this.serviceUrl = serviceUrl;
    }

    public async getUserAuthMethod(): Promise<any> {
        try {
            const { data } = await axios.get(
                `${this.serviceUrl}/v1/userAuthMethod`,
                buildAuthorizationConfig()
            );
            return { succesful: true, data: data.result };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async activateUserAuthMethod(method: string): Promise<any> {
        try {
            const { data } = await axios.post(
                `${this.serviceUrl}/v1/activate`, { method }, buildAuthorizationConfig()
            );
            return { succesful: true, data: data.result };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async generateUserPin(): Promise<any> {
        try {
            const { data } = await axios.post(
                `${this.serviceUrl}/v1/pin/generate`,
                {},
                buildAuthorizationConfig()
            );
            return { succesful: true, data: data.result };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async verifyUserPin(body: any): Promise<any> {
        try {
            const { data } = await axios.post(
                `${this.serviceUrl}/v1/pin/validate`,
                body,
                buildAuthorizationConfig()
            );
            return { succesful: true, data: data.result };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async generateUserOtp(): Promise<any> {
        try {
            const { data } = await axios.get(
                `${this.serviceUrl}/v1/generate-secret`,
                buildAuthorizationConfig()
            );
            console.log("from server", data)
            return { succesful: true, qrCode: data.qrCodeUrl, secret: data.secret };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async verifyUserOtp(body: any): Promise<any> {
        try {
            const { data } = await axios.post(
                `${this.serviceUrl}/v1/verify-otp`,
                body,
                buildAuthorizationConfig()
            );
            return { succesful: true, data: data.result };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async checkUserAvailability(email: any): Promise<any> {
        try {
            const { data } = await axios.get(
                `${this.serviceUrl}/v1/availability?email=${email}`,
                buildAuthorizationConfig()
            );
            return { succesful: true, data: { available: data.avaliable, message: data.message } };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

}

const userAuthMethodService = new UserAuthMethodService(USER_MANAGER_SERVICE_BACKEND);
export default userAuthMethodService;