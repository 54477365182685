import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IonContent } from "@ionic/react";
import { Navigation } from "swiper";
import PasswordUpdate from "./Options/PasswordUpdate";
import AuthMethods from "./Options/AuthMethods";
import IconWrong from "../../../assets/icon/config/WRONG.svg";
import ButtonLink from "../../Buttons/ButtonLink/ButtonLink";
import Modal from "../../Modal/Modal";
import Title from "../../Title/Title";
import "./Security.scss";
import 'swiper/css';

interface SecurityProps {
    home?: any;
}

const Security: React.FC<SecurityProps> = ({ home }) => {

    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalResponse, setModalResponse] = useState<any>({});

    const swiperNavPrevRef = useRef<any>();
    const swiperNavNextRef = useRef<any>();

    const [swaperInstance, setSwiperInstance] = useState<any>();
    const [security, setSecurity] = useState<string>('');

    const changeSlide = (swaperInstance: any, event: string) => {
        switch (event) {
            case 'next':
                swaperInstance.slideNext(500);
                break;
            case 'prev':
                swaperInstance.slidePrev(500);
                break;
        }
    };

    const showWithdrawComponent = () => {
        switch (security) {
            case 'password':
                return (
                    <PasswordUpdate setShowModal={setShowModal} setModalResponse={setModalResponse} backClick={() => { changeSlide(swaperInstance, "prev") }} />
                )
            case 'pin':
                return (
                    <AuthMethods backClick={() => { changeSlide(swaperInstance, "prev") }} />
                )
        }
    }

    return (
        <IonContent className="ion-content">
            <Swiper modules={[Navigation]}
                navigation={{
                    prevEl: swiperNavPrevRef.current,
                    nextEl: swiperNavNextRef.current
                }}
                slidesPerView={1}
                spaceBetween={400}
                allowTouchMove={false}
                onInit={(swiper: any) => {
                    swiper.params.navigation.prevEl = swiperNavPrevRef.current;
                    swiper.params.navigation.nextEl = swiperNavNextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
                <SwiperSlide>
                    <div className="swiper-slide__container" >
                        <Title title="Seguridad" arrows={[true, false]} backClick={home} />
                        <ButtonLink icon="b" mode={""} label={"Actualizar contraseña"} outline="both" onClick={() => { setSecurity('password'); changeSlide(swaperInstance, "next") }} />
                        <ButtonLink icon="b" mode={""} label={"Métodos de autenticación"} outline="bottom" onClick={() => { setSecurity('pin'); changeSlide(swaperInstance, "next") }} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {showWithdrawComponent}
                </SwiperSlide>
            </Swiper>
            <Modal
                icon={modalResponse.icon || IconWrong}
                title={modalResponse.title}
                content={modalResponse.content}
                label={"Continuar"}
                show={showModal}
                onClick={() => {
                    setShowModal(false);
                }}
            />
        </IonContent>
    );
};

export default Security;