import { USER_MANAGER_SERVICE_BACKEND } from "../../BackendServicesExports";
import axios from "axios";

class SessionService {

    private userManagerServiceUrl: string;

    constructor(userManagerServiceUrl: string) {
        this.userManagerServiceUrl = userManagerServiceUrl;
    }

    public getTokenFromStorage() {
        return localStorage.getItem("token");
    }

    public setTokenToStorage(token: string) {
        localStorage.setItem("token", token);
    }

    public async verifyTokenValidity(): Promise<boolean> {
        const token = this.getTokenFromStorage();
        if (token) {
            const result = await this.verifyToken(token);
            return result;
        }
        else return false;
    }

    private async verifyToken(token: string): Promise<any> {
        try {
            const result: any = await axios.post(`${this.userManagerServiceUrl}/v1/verify/jwt`, {}, { headers: { Authorization: `Bearer ${token}` } });
            if (result?.data?.status === "Success")
                return true;
            else false;
        } catch (error) {
            return false;
        }
    }
}

const sessionService = new SessionService(USER_MANAGER_SERVICE_BACKEND);
export default sessionService;




