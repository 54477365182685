import { CheckResult, CheckType, Verifier } from "./Verfier";

class ZipCodeVerifier implements Verifier<string> {
  public check(value: string): CheckResult {
    const regex: RegExp = /^[0-9]{5,11}$/g;
    if (!regex.test(value)) {
      return {
        message:
          "El código postal debe contener entre 5 y 11 caracteres numéricos",
        type: CheckType.DANGER,
      };
    }
    return { message: "", type: CheckType.VALID };
  }
}

const zipCodeVerifier = new ZipCodeVerifier();
export default zipCodeVerifier;
