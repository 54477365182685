import React from "react";
import "./Validator.scss";

interface ValidatorProps {
    showAlert: boolean;
    type?: string;
    message?: string;
}

const Validator: React.FC<ValidatorProps> = ({ showAlert, message, type }) => {

    return (
        <div className="msg_alert">
            {showAlert ? <p className={`${type}`}>{message}</p> : <></>}
        </div>
    );
};

export default Validator;