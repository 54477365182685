import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  show: boolean;
  title: any;
  label: string;
  disable: boolean;
  icon: any;
  content: string;
  secondaryContent: string;
  onClick: () => void;
  altButtonLabel: string;
  onAltClick: () => void;
  hideButtons: boolean;
}

const initialState: ModalState = {
  show: false,
  title: "",
  label: "",
  disable: false,
  icon: "",
  content: "",
  secondaryContent: "",
  onClick: () => null,
  altButtonLabel: "",
  onAltClick: () => null,
  hideButtons: false,
};

export const modalSlice: any = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state) => {
      state.show = false;
    },
    triggerModal: (state, action: PayloadAction<ModalState>) => {
      state.show = true;
      state.title = action.payload.title;
      state.label = action.payload.label || "Continuar"; // ? Mejorar
      state.disable = action.payload.disable;
      state.icon = action.payload.icon;
      state.content = action.payload.content;
      state.secondaryContent = action.payload.secondaryContent
      state.onClick = action.payload.onClick;
      state.altButtonLabel = action.payload.altButtonLabel;
      state.onAltClick = action.payload.onAltClick;
      state.hideButtons = action.payload.hideButtons;
    },
  },
});

export const { triggerModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
