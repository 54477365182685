import { COUNTRIES_SERVICE_BACKEND } from "../../BackendServicesExports";
// import { ILogin, ILoginResponse } from "./RegisterModels";
import axios from "axios";

class CountriesService {

    private countriesServiceUrl: string;

    constructor(countriesServiceUrl: string) {
        this.countriesServiceUrl = countriesServiceUrl;
    }

    public async getAllCountries(): Promise<any> {
        try {
            const result = await axios.get(`${this.countriesServiceUrl}/countries`);
            return { succesful: true, data: result.data.data };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message };
        }
    }
}

const countriesService = new CountriesService(COUNTRIES_SERVICE_BACKEND);
export default countriesService;