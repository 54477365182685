
import { CheckResult, CheckType, Verifier } from "./Verfier";

import { isValidIBAN } from "ibantools";

class IbanVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        if (!isValidIBAN(value)) return { message: "IBAN invalido", type: CheckType.DANGER };
        return { message: "", type: CheckType.VALID };
    }
}

const ibanVerifier = new IbanVerifier();
export default ibanVerifier;
