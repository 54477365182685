export function buildAccountDetails(account: any) {
    if (!account) return [];
    return [
        { label: "Banco:", value: account.bankName },
        { label: "Tipo de cuenta", value: account.accountType },
        { label: "Número de cuenta:", value: account.accountNumber },
        { label: "Titular:", value: account.accountHolder },
        { label: "NIT:", value: account.NIT },
    ]
}

export const defaultAccountOptions = [
    {
        accountId: 99,
        bankName: "Bancolombia",
        accountType: "Corriente",
        accountNumber: "08400000291",
        accountHolder: "Intelligent trading machines S.A.S.",
        NIT: "901.002.150",
    }
]