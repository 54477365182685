import React, { useEffect, useState } from "react";
import { getTransactionStatusInformation, getTransactionTypeInformation } from "../TransferUtils";
import { IonContent, IonIcon, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import ActivityModal from "../ActivityModal/ActivityModal";
import ButtonLink from "../../Buttons/ButtonLink/ButtonLink";
import LoadingView from "../../LoadingView/LoadingView";
import Button from "../../Buttons/Button/Button";
import "./Activity.scss";

interface ActivityProps { }

const Activity: React.FC<ActivityProps> = () => {
    const [transactions, setTransactions] = useState<any[]>([]);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [offset, setOffset] = useState<number>(0);
    const [disableLoadButton, setDisableLoadButton] = useState<boolean>(true);
    const [sortedTransactions, setSortedTransactions] = useState<any[]>([]);
    const [currentTransaction, setCurrentTransaction] = useState<any[]>([]);
    const [openActivityModal, setOpenActivityModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);

    const [filterType, setFilterType] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>("");
    const [reference, setReference] = useState<string>("");
    const [filter, setFilter] = useState<string>("");

    const [isOnView, setIsOnView] = useState<boolean>(false)

    const itemsPerPage = 10;

    const [showSearch, setShowSearch] = useState<boolean>(false);

    useIonViewDidEnter(() => {
        setIsOnView(true)
        getTransactions();
        transactionService.updateExternalDeposit();
    });

    useIonViewDidLeave(() => {
        setIsOnView(false)
    });

    useEffect(() => {
        getTransactions();
    }, [offset]);

    useEffect(() => {
        if (transactions.length === 0) return;
        filterTransactions(transactions);
    }, [transactions]);

    useEffect(() => {
        if (reference === "") return;
        getTransactions();
    }, [reference]);

    useEffect(() => {
        if (filter === "") return;
        firterByTerm();
    }, [filter]);

    const getTransactions = async (filtered: boolean = false) => {
        try {
            setIsLoading(true);
            const result = await transactionService.getTransactions(
                offset,
                itemsPerPage,
                filterType,
                filterStatus,
                reference,
                startDate,
                endDate
            );
            const response = result.data?.transactions || [];
            fixTransactionsDate(response);
            if (!result.data?.transactions || response.length === 0) {
                setDisableLoadButton(true);
                return;
            }
            if (filtered) {
                setTransactions([...response]);
            } else {
                setTransactions([...transactions, ...response]);
            }
            setName(result.data?.name);
            setEmail(result.data?.email);
            if (response.length < itemsPerPage) {
                setDisableLoadButton(true);
                return;
            }
            setDisableLoadButton(false);
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    const fixTransactionsDate = (items: any) => {
        items.forEach((transaction: any) => {
            let date: any = new Date(transaction.timestamp)
                .toLocaleString("en-ES", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                .split(",");
            let date2: any = new Date(date[0]).toISOString().split("T")[0].replaceAll("-", ".");
            transaction.transactionCreatedAt = date2 + ", " + date[1];
        });
    };

    const filterTransactions = (items: any) => {
        let filtered: any = [];
        items.forEach((transaction: any) => {
            let formattedDate = new Date(transaction.timestamp)
                .toLocaleString("en-ES", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                .split(",")[0];
            formattedDate = new Date(formattedDate).toISOString().split("T")[0].replaceAll("-", ".");
            if (!filtered.some((item: any) => item.date === formattedDate)) {
                filtered.push({
                    date: formattedDate,
                    items: [transaction],
                });
                return;
            }
            const dateRange = filtered.find((item: any) => item.date === formattedDate);
            dateRange?.items?.push(transaction);
        });
        setSortedTransactions([...filtered]);
    };

    const filterTicker = (transaction: any) => {
        if (transaction.fromDetails.ticker === "USDC_POLYGON_NXTB" || transaction.fromDetails.ticker === "USDC_AVAX_FUJI") {
            return transaction.fromDetails.total;
        }
        return transaction.toDetails.total;
    };

    const firterByTerm = () => {
        const result: any[] = [];
        transactions.forEach((transaction: any) => {
            if (
                getTransactionStatusInformation(transaction?.TransactionStatus?.status)
                    .status?.toLowerCase()
                    .includes(filter.toLocaleLowerCase()) ||
                getTransactionTypeInformation(transaction?.TransactionTypes?.type)
                    .type?.toLowerCase()
                    .includes(filter.toLocaleLowerCase()) ||
                JSON.stringify(transaction).toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                new Date(transaction.transactionCreatedAt)
                    .toISOString()
                    .split("T")[0]
                    .replaceAll("-", ".")
                    .includes(filter)
            ) {
                result.push(transaction);
            }
        });
        if (transactions.length !== result.length) {
            setDisableLoadButton(true);
        }
        filterTransactions([...result]);
    };

    const handleClick = (transaction: any) => {
        setCurrentTransaction(transaction);
        setOpenActivityModal(true);
    };

    const handleSearch = () => {
        setOffset(0);
        setTransactions([]);
        setSortedTransactions([]);
        setShowSearch(false);
        setDisableLoadButton(true);
        getTransactions(true); // ? Cambiar esto
    };

    const handleResetParams = () => {
        setEndDate(null);
        setStartDate(null);
        setFilterStatus("");
        setFilterType("");
    };

    return (
        <IonContent>
            <div className="activity">
                <div className="activity__title">
                    <h2 className="activity__title-text">Actividad</h2>
                    <form className="activity__title-form">
                        <input
                            disabled={transactions.length === 0}
                            placeholder="Buscar"
                            onChange={(e) => setFilter(e.target.value)}
                        />
                        <div
                            className="activity__title-btn"
                            onClick={() => {
                                handleResetParams();
                                setShowSearch(!showSearch);
                            }}
                        >
                            <IonIcon icon={searchOutline} />
                        </div>
                    </form>
                    {showSearch && (
                        <div className="activity__title-search">
                            <form className="activity__title-search-form">
                                <div className="activity__title-search-form-dates">
                                    <label>Fecha inicial</label>
                                    <input
                                        style={{ height: 49, width: "176px", padding: 10 }}
                                        type={"date"}
                                        id="start"
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <label>Fecha final</label>
                                    <input
                                        style={{ height: 49, width: "176px", padding: 10 }}
                                        type={"date"}
                                        id="end"
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className="activity__title-search-options">
                                    <label>Tipo</label>
                                    <select onChange={(e) => setFilterType(e.target.value)}>
                                        <option value={""}>Todas</option>
                                        <option value={"DEPOSIT"}>Deposito</option>
                                        <option value={"OTC_DEPOSIT"}>Deposito OTC</option>
                                        <option value={"EXTERNAL_DEPOSIT"}>Deposito Externo</option>
                                        <option value={"WITHDRAWAL"}>Retiro</option>
                                        <option value={"OTC_WITHDRAWAL"}>Retiro OTC</option>
                                        <option value={"EXTERNAL_WITHDRAWAL"}>Retiro Externo</option>
                                        <option value={"TRANSFER"}>Transferencia</option>
                                    </select>
                                    <label>Estado</label>
                                    <select onChange={(e) => setFilterStatus(e.target.value)}>
                                        <option value={""}>Todas</option>
                                        <option value={"RECEIVED"}>Recibida</option>
                                        <option value={"COMPLETED"}>Completada</option>
                                        <option value={"REJECTED"}>Rechazada</option>
                                        <option value={"APPROVED"}>Aprobada</option>
                                        <option value={"SUBMITTED"}>En proceso</option>
                                    </select>
                                    <Button isIcon={false} label="Buscar" onClick={() => handleSearch()} />
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                {isLoading && sortedTransactions.length === 0 && (
                    <div className="activity__loading">
                        <LoadingView show />
                    </div>
                )}
                {sortedTransactions.length === 0 && !isLoading && (
                    <span className="activity__alert">No hay registros de actividad</span>
                )}
                {sortedTransactions.map((item: any, idx: number) => (
                    <div key={idx}>
                        <p className="activity__date">{item.date?.replaceAll("-", ".")}</p>
                        {item.items.map((transaction: any, idx: number) => (
                            <ButtonLink
                                key={idx}
                                mode={"list"}
                                label={name ?? email}
                                subLabel={getTransactionTypeInformation(transaction?.TransactionTypes?.type).type}
                                icon={getTransactionTypeInformation(transaction?.TransactionTypes?.type).icon}
                                subInfoType={getTransactionStatusInformation(transaction?.TransactionStatus?.status).type}
                                subInfoData={
                                    getTransactionStatusInformation(transaction?.TransactionStatus?.status).status ?? transaction.status
                                }
                                outline="top"
                                info={filterTicker(transaction)}
                                onClick={() => handleClick(transaction)}
                            />
                        ))}
                    </div>
                ))}
                <div className="activity__load"></div>
                {!disableLoadButton && (
                    <Button
                        isIcon={false}
                        label="Mostrar más"
                        onClick={() => setOffset((prev) => (prev += itemsPerPage))}
                    />
                )}
            </div>
            <ActivityModal
                transaction={{ ...currentTransaction }}
                open={openActivityModal}
                onClose={() => setOpenActivityModal(false)}
            />
        </IonContent>
    );
};

export default Activity;
