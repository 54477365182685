import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { IonPage, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import IconInvalidAccess from "../../assets/images/pup_land/Invalid_access.svg";
import Header from "../../components/Header/Header";
import Charge from "../../components/Transfer/Charge/Charge";
import Modal from "../../components/Modal/Modal";
import "./ChargePage.css";
import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../store/modalReducer";

const ChargePage: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [initSwiper, setInitSwiper] = useState<boolean>(false);

    const [modalGoTo, setModalGoTo] = useState<string>("/dashboard/config");
    const [isModalButtonDisabled, setModalButtonDisabled] = useState<boolean>(true);
    
    const [render, setRender] = useState<boolean>(false);

    useEffect(() => {
        setModalButtonDisabled(isModalButtonDisabled);
    }, []);

    useEffect(() => {
        setInitSwiper(false);
    }, [initSwiper]);

    const goDashboardPage = () => history.push("/dashboard/viio");

    const modalHandler = (message: string, disable: boolean, title?: string, icon?: any, page?: string, hideButtons?: boolean, secondaryContent?: string, label?: string) => {
        let modalAction: any = () => handleClose(page || "/response")
        if (page === "close") modalAction = () => dispatch(closeModal())
        dispatch(triggerModal({
            content: message,
            disable: disable,
            title: title || "Transaccion",
            icon: icon || IconInvalidAccess,
            hideButtons: hideButtons || false,
            secondaryContent: secondaryContent,
            label: label,
            onClick: modalAction
        }))
    };

    const handleClose = (redirect: string) => {
        console.log("Goto", redirect)
        setInitSwiper(true);
        dispatch(closeModal())
        history.push(redirect);
    };

    // ! SOLUCION TEMPORAL PARA RENDERIZAR DE NUEVO EL COMPONENTE
    useIonViewDidLeave(() => setRender(false));
    useIonViewDidEnter(() => setRender(true));

    return (
        <IonPage>
            <Header viio={false} />
            {render && (
                <Charge
                    home={goDashboardPage}
                    modalInformation={modalHandler}
                    resetSwiper={initSwiper}
                />
            )}
        </IonPage>
    );
};

export default ChargePage;
