import { IonPage } from "@ionic/react";
import Activity from "../../components/Transfer/Activity/Activity";
import "./ActivityPage.css";

const ActivityPage: React.FC = () => {

    return (
        <IonPage>
            <Activity />
        </IonPage>
    )
}

export default ActivityPage;