import { IonPage } from "@ionic/react";
import ExchangeRate from "../../components/Transfer/ExchangeRate/ExchangeRate";
import "./Viio.css";

const Viio: React.FC = () => {

    return (
        <IonPage>
            <ExchangeRate />
        </IonPage>
    )
}

export default Viio;