import { cloudyNight } from "ionicons/icons";
import { CheckResult, CheckType, Verifier } from "./Verfier";

class PasswordVerifier implements Verifier<string>{

    public check(password: string): CheckResult {
        // 8 caracteres, 1 mayúscula, 1 minúscula, 1 número y 1 caracter especial
        // const regex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.$!%*?&#+,<>~)(])[A-Za-z\d@.$!%*?&#+,<>~)(]{8,}$/
        let responses: string[] = [];
        if (!/^.{8,}/.test(password)) responses.push(" 8 caracteres");
        if (!/[a-z]/.test(password)) responses.push(" 1 minuscula");
        if (!/[A-Z]/.test(password)) responses.push(" 1 mayuscula");
        if (!/[0-9]/.test(password)) responses.push(" 1 número");
        if (!/[@.$!%*?&#+,<>~)(]/.test(password)) responses.push(" 1 caracter especial [@.$!%*?&#+,<>~]");
        if (responses.length > 0) {
            return { message: "La contraseña debe tener al menos " + responses.toString(), type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }

    public match(password: string, passwordConfirmation: string): CheckResult {
        if (password !== passwordConfirmation) {
            return { message: "Las contraseñas deben ser iguales", type: CheckType.DANGER }
        }
        return  { message: "", type: CheckType.VALID };
    }
}

const passwordRules = new PasswordVerifier();
export default passwordRules;
