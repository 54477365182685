import { LOGIN_SERVICE_BACKEND, SESSION_SERVICE_BACKEND } from "../../services/BackendServicesExports";
import { useHistory } from "react-router";
import { IonPage } from "@ionic/react";
import Config from "../../components/Account/Config/Config";
import "./ConfigPage.css";

const ConfigPage: React.FC = () => {

    const history = useHistory();

    const goToSecurityPage = () => {
        history.push("/security");
    };

    const OnGoToWallet = () => {
        history.push("/wallet");
    }

    const redirectToLogin = () => {
        history.push("/login");
    }

    return (
        <IonPage>
            <Config securityButton={goToSecurityPage} handleGoToWallet={OnGoToWallet} redirectToLogin={redirectToLogin} />
        </IonPage>
    )
}

export default ConfigPage;