import { AUD, CAD, USDC, COP, ETH, EUR, GBP, MXN, USD, USDT, VIIO } from "../../../types/export";
import React, { useEffect, useRef, useState } from "react";
import commissionIcon from "../../../assets/icon/cw/commission.svg";
import rate from "../../../assets/icon/cw/rate.svg";
import "./PairInput.scss";

interface PairInputProps {
    info: number;
    exchangedPrice: string;
    commission: number;
    total?: any;
    senderCurrencyCallback?: any;
    userBalance?: any;
    disabled?: boolean;
    limits?: any;
    rateDecimals: number;
}

const PairInput: React.FC<PairInputProps> = ({
    info,
    commission,
    exchangedPrice,
    total,
    senderCurrencyCallback,
    userBalance,
    disabled,
    limits,
    rateDecimals = 2,
}) => {
    let formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: rateDecimals === 0 ? 2 : rateDecimals,
    });

    const select = useRef<any>();

    const [baseTotal, setBaseTotal] = useState<string>("0");
    const [quoteTotal, setQuoteTotal] = useState<string>("0");
    const [senderCurrency, setSenderCurrency] = useState<string>("COP");

    useEffect(() => {
        console.log("rateDecimals", rateDecimals);
        formatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: rateDecimals === 0 ? 2 : rateDecimals});
    }, [senderCurrency]);

    useEffect(() => {
        setBaseTotal("0");
        setQuoteTotal("0");
    }, [senderCurrency]);

    useEffect(() => {
        updateTotal();
    }, [baseTotal, quoteTotal]);

    const updateTotal = () => {
        let basePrice = baseTotal.replace(/,/g, "");
        let quotePrice = quoteTotal.replace(/,/g, "");
        total({ basePrice, quotePrice, senderCurrency });
    };

    const changeBaseQuantity = (e: any) => {
        let value = e.target.value.replace(/[^0-9,.]/g, "");
        value = value.replace(/,/g, "");
        value = value.split(".");
        if (value[1] !== undefined) {
            if (value[1].length > rateDecimals) return;
            setBaseTotal(formatter.format(value[0]) + "." + (value[1] ?? ""));
            setQuoteTotal(formatter.format(applyExchangeRate("USDC", value[0], value[1])));
        } else {
            setBaseTotal(formatter.format(value[0]));
            setQuoteTotal(formatter.format(applyExchangeRate("USDC", value[0])));
        }
    };

    const changeQuoteQuantity = (e: any) => {
        let value = e.target.value.replace(/[^0-9,.]/g, "");
        value = value.replace(/,/g, "");
        value = value.split(".");
        if (value[1] !== undefined) {
            if (value[1].length > rateDecimals) return;
            setQuoteTotal(formatter.format(value[0]) + "." + (value[1] ?? ""));
            setBaseTotal(formatter.format(applyExchangeRate("", value[0], value[1])));
        } else {
            setQuoteTotal(formatter.format(value[0]));
            setBaseTotal(formatter.format(applyExchangeRate("", value[0])));
        }
    };

    const applyExchangeRate = (exchangeRate: string, value: number, decimalValue?: number) => {
        let rate = 0;
        if (parseFloat(exchangedPrice) === 0) return value * rate;
        if (exchangeRate === "USDC") rate = parseFloat(exchangedPrice);
        if (exchangeRate === "") rate = 1 / parseFloat(exchangedPrice);
        if (decimalValue) {
            let decimalValueString = decimalValue.toString();
            decimalValueString = decimalValueString.slice(0, decimalValue || 2);
            decimalValue = parseInt(decimalValueString);
            let total =
                decimalValueString[1] !== undefined
                    ? value * rate + 0.01 * decimalValue * rate
                    : value * rate + 0.1 * decimalValue * rate;
            return cleanUp(total);
        }
        return value * rate;
    };

    const cleanUp = (total: any): number => {
        total = total.toString().split(".");
        if (total[1]?.length > 2) total[1] = total[1].slice(0, 2);
        total = parseFloat(total[0] + "." + total[1]);
        return total;
    };

    const changeSenderCurrency = () => {
        setSenderCurrency(select.current.value);
        if (senderCurrencyCallback) senderCurrencyCallback(select.current.value);
    };

    const showSenderCurrencyLogo = () => {
        switch (senderCurrency) {
            case "USDC":
                return USDC;
            case "COP":
                return COP;
            case "AUD":
                return AUD;
            case "CAD":
                return CAD;
            case "ETH":
                return ETH;
            case "EUR":
                return EUR;
            case "GBP":
                return GBP;
            case "MXN":
                return MXN;
            case "USD":
                return USD;
            case "USDT":
                return USDT;
            case "VIIO":
                return VIIO;
        }
    };

    return (
        <div className="pair-input">
            <form>
                <div>
                    <label className="pair-input__title">{info === 0 ? "Recibes:" : "Retirar"}</label>
                    <div className="pair-input__box">
                        <div className="pair-input__box-icon">
                            <img src={USDC} alt="" />
                        </div>
                        <div className="pair-input__box-input">
                            <input
                                disabled={disabled}
                                id="sending_quantity"
                                type="tel"
                                placeholder="0"
                                autoComplete="off"
                                name="sendingQuantity"
                                onChange={changeBaseQuantity}
                                value={baseTotal}
                            />
                        </div>
                        <div className="pair-input__box-currency">
                            <p>USDC</p>
                        </div>
                    </div>
                </div>
                <div className="pair-input__info">
                    <div className="pair-input__info-icon">
                        <img src={rate} alt="" />
                    </div>
                    <p className="pair-input__info-text">
                        1.00 USDC = {exchangedPrice} {senderCurrency}
                    </p>
                </div>
                {info === 1 && (
                    <div className="pair-input__info top-0 no-icon accent">
                        <p className="pair-input__info-text">{userBalance?.availableBalance} USDC Disponibles</p>
                    </div>
                )}
                <div>
                    <label className="pair-input__title">{info === 0 ? "Pagas:" : "Recibir"}</label>
                    <div className="pair-input__box">
                        <div className="pair-input__box-icon">
                            <img src={showSenderCurrencyLogo()} alt="" />
                        </div>
                        <div className="pair-input__box-input">
                            <input
                                disabled={disabled}
                                id="recipient_quantity"
                                type={"tel"}
                                placeholder="0"
                                autoComplete="off"
                                name="reciveQuantity"
                                onChange={changeQuoteQuantity}
                                value={quoteTotal}
                            />
                        </div>
                        <div className="pair-input__box-currency">
                            <p>{info === 0 ? "COP" : ""}</p>
                        </div>

                        {info === 1 && (
                            <div className="pair-input__box-currency">
                                <select
                                    name="selectedRecipientCurrency"
                                    id="selectedRecipientCurrency"
                                    onChange={() => changeSenderCurrency()}
                                    ref={select}
                                >
                                    {limits?.map((limit: any) => (
                                        <option value={limit["Assets.details"]?.symbol}>
                                            {limit["Assets.details"]?.symbol}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
                <div className="pair-input__info">
                    <div className="pair-input__info-icon">
                        <img src={commissionIcon} alt="" />
                    </div>
                    <p className="pair-input__info-text">COMISIÓN: {commission} USDC</p>
                </div>
                {info === 1 && (
                    <div className="pair-input__info top-0 no-icon accent">
                        <p className="pair-input__info-text">
                            {senderCurrency !== "USDC"
                                ? "Recibes tus fondos en 2 días hábiles"
                                : "En un momento recibirás tus fondos"}
                        </p>
                    </div>
                )}
            </form>
        </div>
    );
};

export default PairInput;
