import { FC, useState, useEffect } from "react";
import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import Validator from "../../../Validator/Validator";
import clabeVerifier from "../../../../services/Verifiers/ClabeVerifier";
import curpVerifier from "../../../../services/Verifiers/CurpVerifier";

interface MXNProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const MXN: FC<MXNProps> = ({ handleGetData, defaultValues }) => {
  const [clabe, setClabe] = useState<any>({ type: CheckType.INIT, message: ""});
  const [curp, setCurp] = useState<any>({ type: CheckType.INIT, message: ""});

  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit])

  const handleClabe = (target: any) => {
    target.value = /^[0-9]{0,18}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el CLABE");
    else checker = clabeVerifier.check(target.value);
    setClabe(checker);
    saveSubmit(target.value, target.name)
  };

  const handleCurp = (target: any) => {
    target.value = /^[a-zA-Z0-9]{0,18}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el CURP/RFC");
    else checker = curpVerifier.check(target.value);
    setCurp(checker);
    saveSubmit(target.value, target.name)
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({...submit})
  }

  const checkData = () => {
    const options = [clabe, curp];
    const isValid = options.every((option: any) => option.type === CheckType.VALID);
    handleGetData({
      isValid: isValid,
      data: { ...submit, accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}` }
    })
  }
  return (
    <form>
      <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          type="text"
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*CLABE`}</label>
        <input name={"transferClabe"} onChange={(e) => handleClabe(e.target)} />
        <Validator
          showAlert={clabe.type !== CheckType.VALID}
          message={clabe.message}
          type={clabe.type}
        />
      </div>
      <div>
        <label>{`*CURP/RFC`}</label>
        <input name="transferCurp" onChange={(e) => handleCurp(e.target)} />
        <Validator
          showAlert={curp.type !== CheckType.VALID}
          message={curp.message}
          type={curp.type}
        />
      </div>
    </form>
  );
};

export default MXN;
