import React from "react";
import symb from "../../assets/images/LOGO SYMB.svg";
import text from "../../assets/images/LOGO TYPE BLACK.svg";
import "./LoadingView.scss";

interface LoadingViewProps {
    show: boolean;
    ignoreContainer?: boolean;
}

const LoadingView: React.FC<LoadingViewProps> = ({ show, ignoreContainer }) => {

    return (
        <>
            {show &&
                <div className={ignoreContainer ? "" : "loading"}>
                    <div className="loading__logo">
                        <div className="loading__logo-symbol">
                            <img src={symb} alt="VIIO Loading" width="60" />
                        </div>
                        <div className="loading__logo-text">
                            <img src={text} alt="VIIO Loading" width="60" />
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

export default LoadingView;