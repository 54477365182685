import React, { ChangeEvent, useState } from "react";
import { CheckResult, CheckType } from "../../../services/Verifiers/Verfier";
import { IonContent } from "@ionic/react";
import { useHistory } from "react-router";
import emailVerifier from "../../../services/Verifiers/EmailVerfier";
import IconEmail from "../../../assets/images/pup_land/Email_1.svg";
import IconInvalidEmail from "../../../assets/images/pup_land/Invalid_email.svg";
import Validator from "../../Validator/Validator";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import "./RecoverPassword.scss";
import "swiper/css";
import userService from "../../../services/Services/UserService/UserService";
import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../../store/modalReducer";

interface RecoverPasswordProps {}

const RecoverPassword: React.FC<RecoverPasswordProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailAlert, setEmailAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });

  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailAlert(emailVerifier.check(e.target.value));
    setEmail(e.target.value);
  };

  const goDashboard = () => {
    history.push("/login");
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const result = await userService.sendPasswordRecoveryEmail({
      email: email,
    });
    setIsLoading(false);
    const userNotFound = result.description === "User credentials not found";
    if (userNotFound || !result.succesful) {
      dispatch(
        triggerModal({
          icon: IconInvalidEmail,
          title: userNotFound ? "El usuario no existe" : "Hubo un problema",
          content: userNotFound
            ? "El correo electronico no se encuentra registrado."
            : "Hubo un problema al enviar el correo.",
          onClick: () => dispatch(closeModal()),
        })
      );
      return;
    }
    dispatch(
      triggerModal({
        icon: IconEmail,
        title: "Recuperación de contraseña",
        label: "Aceptar",
        content:
          "Te enviaremos un link de recuperación a tu correo electrónico",
        onClick: () => {
          dispatch(closeModal());
          goDashboard();
        },
      })
    );
  };

  return (
    <IonContent className="ion-content">
      <Title
        title={"Ingresa tu correo electrónico"}
        arrows={[true, false]}
        backClick={() => goDashboard()}
      />
      <form>
        <p className="info-text">
          Enviaremos un link de recuperación a tu correo registrado para
          verificar que tienes acceso.
        </p>
        <label>Correo electrónico</label>
        <input
          type="email"
          onChange={handleEmail}
        />
        <Validator
          showAlert={emailAlert.type !== CheckType.VALID}
          message={emailAlert.message}
          type={emailAlert.type}
        />
        <div className="recover__button">
          <Button
            disable={emailAlert.type !== CheckType.VALID || isLoading}
            isIcon={false}
            label={"Aceptar"}
            onClick={() => handleSubmit()}
          />
        </div>
      </form>
    </IonContent>
  );
};

export default RecoverPassword;
