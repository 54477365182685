import { formatCurrency, getTransactionStatusInformation, getTransactionTypeInformation } from "../TransferUtils";
import { TransactionType, TransferType } from "../../../types/transactionTypes";

export function buildTransactionDictionary(transaction: any) {
    return {
        details: [
            ...buildDefaultItems(transaction),
            ...buildTransactionDetails(transaction)
        ],
        payment: buildTransactionPaymentDetails(transaction)
    };
}

function buildDefaultItems(transaction: any) {
    return [{
        title: "Tipo de transacción",
        value: getTransactionTypeInformation(transaction?.TransactionTypes?.type).type,
    }, {
        title: "Fecha de transacción",
        value: transaction.transactionCreatedAt
    }, {
        title: "Estado de transacción",
        value: getTransactionStatusInformation(transaction?.TransactionStatus?.status).status,
    }, {
        title: "Tasa de Cambio efectiva",
        value: getTransactionRate(transaction)
    }, {
        title: "Comisión",
        value: `0`,
    }];
}

function getTransactionRate(transaction: any) {
    switch (transaction?.TransactionTypes?.type) {
        case TransactionType.TRANSFER: return transaction?.Rates?.price;
        case TransactionType.EXTERNAL_WITHDRAWAL: return transaction?.Rates?.sellingPrice;
        case TransactionType.EXTERNAL_DEPOSIT: return transaction?.Rates?.buyingPrice;
        case TransactionType.WITHDRAWAL: return transaction?.Rates?.sellingPrice;
        case TransactionType.DEPOSIT: return transaction?.Rates?.buyingPrice;
        case TransactionType.OTC_DEPOSIT: return transaction?.Rates?.price
        case TransactionType.OTC_WITHDRAWAL: return transaction?.toDetails?.details?.rate ?? transaction?.Rates?.sellingPrice; 
        case TransactionType.REFUND: return transaction?.Rates?.price;
        default: return [];
    }
}

function buildTransactionDetails(transaction: any) {
    switch (transaction?.TransactionTypes?.type) {
        case TransactionType.TRANSFER:
        case TransactionType.EXTERNAL_WITHDRAWAL:
        case TransactionType.EXTERNAL_DEPOSIT: return addTotalField(transaction);
        case TransactionType.WITHDRAWAL: return getWithdrawalDetails(transaction);
        case TransactionType.DEPOSIT: return getDepositDetails(transaction);
        case TransactionType.OTC_WITHDRAWAL: return getOtcWithdrawalDetails(transaction);
        case TransactionType.REFUND: return getRefundDetails(transaction);
        default: return [];
    }
}

function addTotalField(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.toDetails?.ticker);
    return [{
        title: `Total`,
        value: `${formatCurrency(transaction?.toDetails?.total)} ${tickerDetails.coin}`,
    }];
}

function getWithdrawalDetails(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.fromDetails?.ticker);
    return [{
        title: `Total ${tickerDetails.coin}`,
        value: `${formatCurrency(transaction?.fromDetails?.total)} ${tickerDetails.coin}`,
    }, {
        title: `Total ${transaction?.toDetails?.ticker} `,
        value: `${formatCurrency(transaction?.toDetails?.total)} ${transaction?.toDetails?.ticker}`,
    }];
}

function getDepositDetails(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.toDetails?.ticker);
    return [{
        title: `Total ${transaction?.fromDetails?.ticker}`,
        value: `${formatCurrency(transaction?.fromDetails?.total)} ${transaction?.fromDetails?.ticker}`,
    }, {
        title: `Total ${tickerDetails.coin} `,
        value: `${formatCurrency(transaction?.toDetails?.total)} ${tickerDetails.coin}`,
    }];
}

function getOtcWithdrawalDetails(transaction: any) {
    return [{
        title: "Fecha de cumplimiento",
        value: new Date(transaction?.toDetails?.details?.validTo * 1000).toLocaleString(),
    }];
}

function getRefundDetails(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.fromDetails?.ticker);
    return [{
        title: `Total`,
        value: `${formatCurrency(transaction?.fromDetails?.total)} ${tickerDetails.coin}`,
    }];
}

function buildTransactionPaymentDetails(transaction: any) {
    switch (transaction?.TransactionTypes?.type) {
        case TransactionType.TRANSFER: return getTransferPaymentDetails(transaction);
        case TransactionType.DEPOSIT: return getDepositPaymentDetails(transaction);
        case TransactionType.OTC_DEPOSIT: return getOtcDepositPaymentDetails(transaction);
        case TransactionType.OTC_WITHDRAWAL: return getOtcWithdrawalPaymentDetails(transaction);
        case TransactionType.WITHDRAWAL: return getWithdrawalPaymentDetails(transaction);
        case TransactionType.EXTERNAL_WITHDRAWAL: return getExternalWithdrawalPaymentDetails(transaction);
        case TransactionType.EXTERNAL_DEPOSIT: return getExternalDepositPaymentDetails(transaction);
        default: return [];
    }
}

function getTransferPaymentDetails(transaction: any) {
    switch (transaction?.fromDetails?.details?.paymentRole) {
        case TransferType.SENDER: return getTransferSenderPaymentDetails(transaction);
        case TransferType.RECEIVER: return getTransferReceiverPaymentDetails(transaction);
        default: return [];
    }
}

function getTransferSenderPaymentDetails(transaction: any) {
    return [{
        title: `Email de receptor`,
        value: ``,
    }, {
        title: `Nombre de receptor`,
        value: ``,
    }];
}

function getTransferReceiverPaymentDetails(transaction: any) {
    return [{
        title: `Email de emisor`,
        value: ``,
    }, {
        title: `Nombre de emisor`,
        value: ``,
    }];
}

function getDepositPaymentDetails(transaction: any) {
    return [
        ...buildAdditionalFields(transaction?.fromDetails?.details, ['bank', 'account_name', 'name', 'document_number', 'paymentMethod']),
    ];
}

function getOtcDepositPaymentDetails(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.toDetails?.ticker);
    return [
        ...buildAdditionalFields(transaction?.fromDetails?.details, ['bank', 'txHash', 'account_name', 'name', 'document_number']),
        {
            title: `Total`,
            value: `${formatCurrency(transaction?.fromDetails?.total)} ${tickerDetails.coin}`,
        }, {
            title: `Red`,
            value: `${tickerDetails.network}`,
        }];
}

function getOtcWithdrawalPaymentDetails(transaction: any) {
    let tickerDetails = parseTickerDetails(transaction?.fromDetails?.ticker);
    return [{
        title: "Tasa de cambio",
        value: `${formatCurrency(transaction?.toDetails?.details?.rate)} ${tickerDetails.coin}/${transaction?.toDetails?.ticker || ""}`,
    }, {
        title: `Total ${tickerDetails.coin}`,
        value: `${formatCurrency(transaction?.fromDetails?.total)} ${tickerDetails.coin}`,
    }, {
        title: `Total ${transaction?.toDetails?.ticker || ""} `,
        value: `${formatCurrency(transaction?.toDetails?.total)} ${transaction?.toDetails?.ticker}`,
    }, {
        title: `Red`,
        value: `${tickerDetails.network}`,
    }];
}

function getWithdrawalPaymentDetails(transaction: any) {
    return [
        ...buildAdditionalFields(transaction?.toDetails?.details, [
            'accountHolderName', 'accountNumber', 'bankName', 'achRoutingNumber', 'network',
            'walletAddress', 'transitNumber', 'institutionNumber', 'ukSortCode', 'bsbCode',
            'iban', 'transferClabe', 'transferCurp', 'city', 'address', 'state', 'zipcode'
        ])];
}

function getExternalWithdrawalPaymentDetails(transaction: any) {
    return [...buildAdditionalFields(transaction?.fromDetails?.details, ['destinationAddress', 'txHash'])];
}

function getExternalDepositPaymentDetails(transaction: any) {
    return [...buildAdditionalFields(transaction?.fromDetails?.details, ['sourceAddress','txHash'])];
}

function parseTickerDetails(ticker: string) {
    let parts = ticker.split("_");
    return { coin: parts[0], network: parts[1] };
}

function buildAdditionalFields(details: any, properties: string[]) {
    let fields: any = [];
    for (let property of properties)
        if (details?.[property] && getPropertyTitle(property))
            fields.push({
                title: getPropertyTitle(property),
                value: details?.[property],
            });
    return fields;
}

function getPropertyTitle(property: string) {
    switch (property) {
        case "bank": return "Banco";
        case "bankName": return "Banco";
        case "achRoutingNumber": return "ACH Routing Number";
        case "network": return "Red";
        case "walletAddress": return "Direccíon de Wallet";
        case "transitNumber": return "Número de transito";
        case "institutionNumber": return "Número de institución";
        case "ukSortCode": return "UK Sort Code";
        case "bsbCode": return "BSB Code";
        case "iban": return "IBAN";
        case "transferClabe": return "CLABE";
        case "transferCurp": return "CURP/RFC";
        case "city": return "Ciudad";
        case "address": return "Dirección";
        case "state": return "Estado";
        case "paymentMethod": return "Medio de pago";
        case "zipcode": return "Código postal";
        case "accountHolderName": return "Titular de la cuenta";
        case "accountNumber": return "Número de cuenta";
        case "account_name": return "Número de cuenta";
        case "name": return "Nombre del titular de la cuenta";
        case "document_number": return "Identificacíon del titular";
        case "txHash": return "Hash de la transacción";
        case "destinationAddress": return "Dirección de destino";
        case "sourceAddress": return "Dirección de origen";
        default: return undefined;
    }
}

export function isOTCTransaction(transaction: any) {
    return transaction?.TransactionTypes?.type === TransactionType.OTC_DEPOSIT || 
        transaction?.TransactionTypes?.type === TransactionType.OTC_WITHDRAWAL; 
}