import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { LOGIN_SERVICE_BACKEND } from "../../BackendServicesExports";
import { ILogin, ILoginResponse } from "./LoginModels";
import sessionService from "../SessionService/SessionService";
import axios from "axios";

class LoginService {

    private loginServiceUrl: string;

    constructor(loginServiceUrl: string) {
        this.loginServiceUrl = loginServiceUrl;
    }

    public async login(loginData: ILogin): Promise<ILoginResponse> {
        try {
            const result = await axios.post(`${this.loginServiceUrl}/v1/login`, loginData, buildAuthorizationConfig());
            sessionService.setTokenToStorage(result.data?.jwt);
            return { succesful: true };
        } catch (error: any) {
            return { succesful: false, description: error?.response?.data?.message, attempts: error?.response?.data?.attempts };
        }
    }

    public async logout() {
        try {
            await axios.post(`${this.loginServiceUrl}/v1/logout`, {}, buildAuthorizationConfig());
            sessionService.setTokenToStorage('');
        } catch (error: any) {
            return error?.response?.data;
        }
    }
}

const loginService = new LoginService(LOGIN_SERVICE_BACKEND);
export default loginService;